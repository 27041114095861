import {
  Box,
  makeStyles,
  Theme,
  createStyles,
  LinearProgress,
} from '@material-ui/core';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import NotFoundIcon from '../../../../shared/components/Icons/NotFoundIcon';
import { Upload } from '../../../../shared/components/Upload';
import { FileProps, useUpload } from '../../../../shared/hooks/useUpload';
import resizeImage from '../../../../shared/utils/resize';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      border: `1px solid ${theme.palette.primary.main}`,
      background: `${theme.palette.background.paper}`,
      color: theme.palette.primary.main,
      borderRadius: 4,
      padding: theme.spacing(1),
      textAlign: 'center',
      fontWeight: 'bold',
    },
    imagePreview: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
      borderRadius: 4,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  })
);

interface UploadImageRecipeProps {
  preview?: string;
  disabled?: boolean;
  isSubmitting?: boolean;
  onFile?: (file: any) => void;
}

export function UploadImageRecipe({
  preview,
  disabled,
  isSubmitting = false,
  onFile = () => {},
}: UploadImageRecipeProps) {
  const classes = useStyles();

  const [imagePreview, setImagePreview] = useState(preview);

  const callback = (image: File) => {
    onFile(image);
  };

  const handleUploadImageRecipe = async (event: any) => {
    const file = event?.target?.files[0] as FileProps;

    setImagePreview(URL.createObjectURL(file as any));

    // onFile(file);
    // Reduzir o tamanho da imagem
    resizeImage(event?.target?.files[0], callback);
  };

  return (
    <motion.div whileTap={{ scale: isSubmitting ? 1 : 0.95 }}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        mb={2}
        position="relative"
      >
        <Upload
          name="recipe"
          onChange={handleUploadImageRecipe}
          disabled={isSubmitting || disabled}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="300px"
            borderRadius={8}
            bgcolor="background.paper"
          >
            {!imagePreview && (
              <Box fontSize="0.88rem" color="primary.main" textAlign="center">
                <NotFoundIcon style={{ fontSize: '4rem' }} />
                <br /> Nenhuma imagem selecionada.
              </Box>
            )}

            {imagePreview && (
              <img
                src={imagePreview}
                alt="Imagem"
                className={classes.imagePreview}
              />
            )}
          </Box>

          <Box position="absolute" bottom={0} left={0} width="100%">
            <Box
              className={classes.button}
              style={{ opacity: isSubmitting ? 0 : 1 }}
            >
              {imagePreview ? 'Alterar' : 'Adicionar'} imagem
            </Box>
          </Box>
        </Upload>
      </Box>
    </motion.div>
  );
}
