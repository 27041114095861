import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { api } from '../../../../../shared/services/apiClient';
import { ICustomData } from '../types';
import { useUserDetailStyles } from './styles';

export function UserDetail() {
  const classes = useUserDetailStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<ICustomData | null>(null);

  const { id } = useParams<{ id: string }>();

  const queryData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`users/${id}`);

      setUser(response.data.result);
    } catch (error) {
      history.push('/users');
    }
    setLoading(false);
  };

  useEffect(() => {
    queryData();
  }, [id]);
  // const { state } = useLocation<{ user: ICustomData }>();

  const isDesktop = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    if (!id) {
      history.push('/users');
    }
  }, [history, id]);

  const handleEdit = () => {
    history.push(`/users/${user?.id}/edit`, { user });
  };

  return (
    <PageContainer>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(230px, 1fr))"
        gridGap={24}
        mb={3}
      >
        <Box order={isDesktop ? 0 : 2}>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {user?.role.name}
              </Typography>
              <Typography variant="h5" component="h2">
                {user?.name}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                <a href={`mailto:${user?.email}`}>{user?.email}</a>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body2" component="p">
                Para editar os dados do usuário clique no botão abaixo:
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={handleEdit}>
                Editar
              </Button>
            </CardActions>
          </Card>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          order={1}
        >
          <Avatar
            src={user?.avatar ? user.avatar_url : undefined}
            alt={user?.avatar}
            style={{ width: 220, height: 220, borderRadius: 60 }}
          />
        </Box>
      </Box>
    </PageContainer>
  );
}
