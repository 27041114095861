import { Video } from '../modules/records/pages/Video';
import { VideoCreate } from '../modules/records/pages/Video/Create';
import { VideoEdit } from '../modules/records/pages/Video/Edit';
import { IRoutes } from './types.routes';

const pathname = 'videos';
const pageName = 'Vídeos';
const pageName2 = 'Vídeo';

export const videosRoutes: IRoutes[] = [
  {
    path: `/${pathname}`,
    children: <Video />,
    isPrivate: true,
    exact: true,
    pageName,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/create`,
    children: <VideoCreate />,
    isPrivate: true,
    exact: true,
    pageName: `Novo ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: `Novo ${pageName2}`, link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/:id/edit`,
    children: <VideoEdit />,
    isPrivate: true,
    exact: true,
    pageName: `Atualizar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: `Atualizar ${pageName2}`, link: `/${pathname}` },
    ],
  },
];
