import { Box, Breadcrumbs, Container, Typography } from '@material-ui/core';
import { FC, ReactNode, useCallback } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import { YourRegistration } from '../../../modules/accounts/components/YourRegistration';
import { TopNavigation } from '../../components/TopNavigation';
import { useAuth } from '../../hooks/useAuth';
import { useStyles } from './styles';

interface DefaultLayoutProps {
  pageName?: string;
  children?: ReactNode;
  historyPush?: string;
  breadcrumb?: Array<{
    name: string;
    link: string;
  }>;
}

const DefaultLayout: FC<DefaultLayoutProps> = ({
  pageName,
  children,
  historyPush,
  breadcrumb,
}) => {
  const { signOutRedirect } = useAuth();
  const history = useHistory();

  const classes = useStyles();

  const signOut = useCallback(() => {
    signOutRedirect();
  }, [signOutRedirect]);

  if (process.env.NODE_ENV === 'development') {
    console.count();
  }

  return (
    <>
      <Box
        width="100%"
        height="100%"
        minHeight="100vh"
        display="flex"
        style={{ overflow: 'hidden' }}
      >
        <TopNavigation signOut={signOut} />

        <Container maxWidth={false} className={classes.content}>
          <Box className={classes.root}>
            <Box
              maxWidth="860px"
              marginX="auto"
              display="flex"
              flexDirection="column"
              mb={3}
            >
              <Breadcrumbs
                maxItems={3}
                aria-label="breadcrumb"
                className={classes.breadcrumb}
              >
                {breadcrumb?.length &&
                  breadcrumb.map((item, index) =>
                    breadcrumb.length === index + 1 ? (
                      <Typography
                        key={item.name}
                        className={classes.breadcrumbActive}
                        color="primary"
                      >
                        {item.name}
                      </Typography>
                    ) : (
                      <Link
                        key={item.name}
                        className={classes.breadcrumbLink}
                        to={item.link}
                      >
                        {item.name}
                      </Link>
                    )
                  )}
              </Breadcrumbs>

              {historyPush && (
                <Box
                  display="flex"
                  flexDirection="row"
                  gridGap={8}
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(historyPush)}
                >
                  <FiArrowLeft fontSize="large" />
                  <Typography variant="h6" noWrap>
                    {pageName}
                  </Typography>
                </Box>
              )}
              {!historyPush && (
                <Typography variant="h6" noWrap>
                  {pageName}
                </Typography>
              )}
            </Box>

            {children}
          </Box>
        </Container>
      </Box>

      {/* Modal Your Registration | module/profile/YourRegistration/index.tsx */}
      <YourRegistration />
    </>
  );
};

export { DefaultLayout };
