import { Box } from '@material-ui/core';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import DataTable from '../../../../shared/components/DataTable';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useQueryPaginate } from '../../../../shared/hooks/reactQuery/useQueryPaginate';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { ICustomData, IResponseData } from './types';

export function AnnualPlanning() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();

  const [annualPlannings, setAnnualPlanning] = useState<ICustomData[]>([]);
  const [total, setTotal] = useState(0);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    status,
    isFetching,
  } = useQueryPaginate<IResponseData>({
    key: ['annual-planning'],
    url: `/admin/annual-planning`,
  });

  useEffect(() => {
    if (data?.pages) {
      const parseAnnualPlanning: ICustomData[] = [];

      data.pages.forEach((group) => {
        setTotal(group.count);

        group.results.forEach((item) => {
          parseAnnualPlanning.push({
            ...item,
            detailsAnualPlannings: item.detailsAnualPlannings.map((item) => ({
              ...item,
              value: Number(item.value),
            })),
            formattedDate: (
              <Box display="flex" alignItems="center" width="100%">
                <FiCalendar />
                <Box ml={1}>
                  {moment(item.event_date).format('DD [de] MMMM')}
                </Box>
              </Box>
            ),
            customTitle: (
              <Box display="flex" flexDirection="column" width="100%">
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  color="primary.dark"
                >
                  <FiCalendar size={24} />
                  <Box ml={1} fontSize="1.2rem" color="primary.dark">
                    {item.title}
                  </Box>
                </Box>

                <Box ml={4}>
                  Evento para o dia{' '}
                  {moment(item.event_date).format('DD/MM/YYYY')}
                </Box>
              </Box>
            ),
          });
        });
      });

      setAnnualPlanning(parseAnnualPlanning);
    }
  }, [data?.pages]);

  const handleCreate = useCallback(() => {
    history.push('/scheduling-date/create');
  }, [history]);

  const handleDelete = useCallback(
    (annualPlanning: IResponseData) => {
      api
        .delete(`/admin/annual-planning/?id=${annualPlanning.id}`)
        .then(() => {
          msgSuccess('Planejamento anual deletado com sucesso.');
          refetch();
        })
        .catch((err) => {
          msgError(err?.response?.data?.message);
        });
    },
    [msgError, msgSuccess, refetch]
  );

  const handleView = useCallback(
    (annualPlanning: IResponseData) => {
      history.push(`/scheduling-date/details/${annualPlanning.id}`, {
        annualPlanning: {
          ...annualPlanning,
          formattedDate: undefined,
          customTitle: undefined,
          formatedEventDate: moment(annualPlanning.event_date).format(
            'DD/MM/YYYY'
          ),
        },
      });
    },
    [history]
  );

  const handleEdit = useCallback(
    (annualPlanning: IResponseData) => {
      history.push(`/scheduling-date/${annualPlanning.id}/edit`, {
        annualPlanning: {
          ...annualPlanning,
          formattedDate: undefined,
          customTitle: undefined,
        },
      });
    },
    [history]
  );

  const tableHeadCells = {
    customTitle: 'Título do Evento',
  };

  return (
    <PageContainer>
      <DataTable
        headCells={tableHeadCells}
        dataRows={annualPlannings}
        dataTotal={total}
        isLoading={status === 'loading' || isFetching}
        errorMessage={error?.message}
        onView={(value) => handleView(value)}
        onEdit={(value) => handleEdit(value)}
        onDelete={(value) => handleDelete(value)}
        onCreate={handleCreate}
        onNextPage={fetchNextPage}
        isDisabledNextPage={!hasNextPage || isFetchingNextPage}
      />
    </PageContainer>
  );
}
