import { ReactElement } from 'react';
import {
  FiHome,
  FiAward,
  FiUsers,
  FiArchive,
  FiClipboard,
  FiSend,
  FiCalendar,
  FiDollarSign,
  FiYoutube,
  FiRss,
} from 'react-icons/fi';

export type IMenu = {
  divider?: boolean;
  dividerEnd?: boolean;
  name: string;
  link: string;
  icon: ReactElement;
};

export const menus: IMenu[] = [
  { name: 'Painel de Controle', link: '/', icon: <FiHome size="1.2rem" /> },
  { name: 'Usuários', link: '/users', icon: <FiUsers size="1.2rem" /> },
  { name: 'Assinaturas', link: '/signatures', icon: <FiAward size="1.2rem" /> },

  {
    divider: true,
    name: 'Carga inicial',
    link: '',
    icon: <></>,
  },

  { name: 'Produtos', link: '/products', icon: <FiArchive size="1.2rem" /> },
  {
    name: 'Fichas Técnicas',
    link: '/recipes',
    icon: <FiClipboard size="1.2rem" />,
  },
  {
    name: 'Planejamento Anual',
    link: '/scheduling-date',
    icon: <FiCalendar size="1.2rem" />,
  },
  {
    dividerEnd: true,
    name: '',
    link: '',
    icon: <></>,
  },

  {
    name: 'Notificações',
    link: '/notifications',
    icon: <FiSend size="1.2rem" />,
  },
  {
    name: 'Propagandas',
    link: '/ads',
    icon: <FiDollarSign size="1.2rem" />,
  },
  { name: 'Blog', link: '/blog', icon: <FiRss size="1.2rem" /> },
  { name: 'Vídeos', link: '/videos', icon: <FiYoutube size="1.2rem" /> },
];
