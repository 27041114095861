import axios, { AxiosError } from 'axios';

import { ENVIRONMENT } from '../../config/environment';
import { signOut } from '../contexts/AuthContext';

interface ResponseRefreshToken {
  token: string;
  refreshToken: string;
}

let isRefreshing = false;
let failedRequestsQueue: Array<{
  onSuccess: (token: string) => void;
  onFailure: (err: AxiosError) => void;
}> = [];

export function setupAPIClient() {
  const getToken = localStorage.getItem('application.auth.token');

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (
          error.response.data?.code === 'token.expired' ||
          error.response.data?.message === 'token.expired'
        ) {
          // renovar o token
          const refreshToken = localStorage.getItem(
            'application.auth.refreshToken'
          );
          const originalConfig = error.config;

          if (!isRefreshing) {
            isRefreshing = true;

            api
              .post<ResponseRefreshToken>('/refresh-token', {
                refreshToken,
              })
              .then((response) => {
                const { token } = response.data;

                localStorage.setItem('application.auth.token', token);

                if (response.data?.refreshToken) {
                  localStorage.setItem(
                    'application.auth.refreshToken',
                    response.data.refreshToken
                  );
                }

                api.defaults.headers.Authorization = `Bearer ${token}`;

                failedRequestsQueue.forEach((request) =>
                  request.onSuccess(token)
                );
                failedRequestsQueue = [];
              })
              .catch((err) => {
                failedRequestsQueue.forEach((request) =>
                  request.onFailure(err)
                );
                failedRequestsQueue = [];

                signOut();
              })
              .finally(() => {
                isRefreshing = false;
              });
          }

          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({
              onSuccess: (token: string) => {
                originalConfig.headers.Authorization = `Bearer ${token}`;

                resolve(api(originalConfig));
              },
              onFailure: (err: AxiosError) => {
                reject(err);
              },
            });
          });
        }
        signOut();
      }

      return Promise.reject(error);
    }
  );

  return api;
}
