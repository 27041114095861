import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { schema } from './schema';

type IForm = {
  message: string;
};

export function NotificationCreate() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      message: '',
    },
  });

  const handlePush = useCallback(() => {
    history.push('/notifications');
  }, [history]);

  const onSubmit = useCallback(
    ({ message }: IForm) => {
      const formatData = {
        message,
      };

      api
        .post('/message', formatData)
        .then(() => {
          msgSuccess('Mensagem cadastrada com sucesso!');
          handlePush();
        })
        .catch((err) => {
          msgError(err.response?.data?.message);
        });
    },
    [handlePush, msgSuccess, msgError]
  );

  return (
    <PageContainer component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" mb={3} width="100%">
        <Input
          name="message"
          multiline
          minRows={4}
          control={control}
          error={errors}
          label="Mensagem"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
          inputProps={{ maxLength: 500 }}
          placeholder="500 caracteres"
        />
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          size="large"
        >
          Enviar
        </Button>
      </Box>
    </PageContainer>
  );
}
