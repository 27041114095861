import { Typography, Box, Divider } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Brightness7Outlined,
  PowerSettingsNewOutlined,
  NavigateNextOutlined,
} from '@material-ui/icons';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../../../shared/components/Flex';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useAuth } from '../../../../shared/hooks/useAuth';
import { useModalYourRegistration } from '../../../../shared/hooks/useModalYourRegistration';
import { useUserAuthenticated } from '../../../../shared/hooks/useUserAuthenticated';
import { AvatarUpload } from '../../components/AvatarUpload';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      alignItems: 'stretch',
    },
    link: {
      cursor: 'pointer',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      background: 'transparent',
      border: 'none',
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
    linkIcon: {
      background: theme.palette.background.paper,
      borderRadius: theme.spacing(1),
      padding: 4,
      marginRight: theme.spacing(2),
      width: theme.spacing(5),
      height: theme.spacing(5),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export function Me() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useUserAuthenticated();
  const { signOutRedirect } = useAuth();
  const { onOpen } = useModalYourRegistration();
  function handlePush(route: string) {
    history.push(route);
  }

  return (
    <PageContainer display="flex" flexDirection="column">
      <Box mb={4} />

      <motion.div whileTap={{ scale: 0.95 }}>
        <AvatarUpload />
      </motion.div>

      <Typography component="div">
        <Box fontSize="18px" fontWeight="bold" textAlign="center" mt={2}>
          {user?.name || ''}
        </Box>
        <Box fontSize="14px" textAlign="center">
          {user?.email}
        </Box>
      </Typography>

      <Flex flexDirection="column" className={classes.root} mt={4}>
        <motion.button
          whileTap={{ scale: 0.95 }}
          className={classes.link}
          onClick={onOpen}
        >
          <Box className={classes.linkIcon}>
            <Brightness7Outlined color="secondary" />
          </Box>
          <Typography color="textPrimary">Seu Cadastro</Typography>
          <NavigateNextOutlined
            color="secondary"
            style={{ marginLeft: 'auto' }}
          />
        </motion.button>

        <Divider />

        <motion.button
          whileTap={{ scale: 0.95 }}
          className={classes.link}
          onClick={() => signOutRedirect()}
        >
          <Box className={classes.linkIcon}>
            <PowerSettingsNewOutlined color="secondary" />
          </Box>
          <Typography color="textPrimary">Sair do App</Typography>
          <NavigateNextOutlined
            color="secondary"
            style={{ marginLeft: 'auto' }}
          />
        </motion.button>
      </Flex>
    </PageContainer>
  );
}
