import { Signature } from '../modules/accounts/pages/Signature';
import { SignatureDetail } from '../modules/accounts/pages/Signature/Detail';
import { IRoutes } from './types.routes';

const pathname = 'signatures';
const pageName = 'Assinaturas';
const pageName2 = 'Assinatura';

export const signaturesRoutes: IRoutes[] = [
  {
    path: `/${pathname}`,
    children: <Signature />,
    isPrivate: true,
    exact: true,
    pageName,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/:id`,
    children: <SignatureDetail />,
    isPrivate: true,
    exact: true,
    pageName: `Detalhes da ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Detalhes', link: `/${pathname}` },
    ],
  },
];
