import { Recipe } from '../modules/records/pages/Recipe';
import { RecipeCreate } from '../modules/records/pages/Recipe/Create';
import { RecipeEdit } from '../modules/records/pages/Recipe/Edit';
import { IRoutes } from './types.routes';

const pathname = 'recipes';
const pageName = 'Fichas Técnicas';
const pageName2 = 'Ficha Técnica';

export const recipesRoutes: IRoutes[] = [
  {
    path: `/${pathname}`,
    children: <Recipe />,
    isPrivate: true,
    exact: true,
    pageName,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/create`,
    children: <RecipeCreate />,
    isPrivate: true,
    exact: true,
    pageName: `Cadastrar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Cadastrar', link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/:id/edit`,
    children: <RecipeEdit />,
    isPrivate: true,
    exact: true,
    pageName: `Atualizar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Editar', link: `/${pathname}` },
    ],
  },
];
