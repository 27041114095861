import { Product } from '../modules/records/pages/Product';
import { ProductCreate } from '../modules/records/pages/Product/Create';
import { ProductEdit } from '../modules/records/pages/Product/Edit';
import { IRoutes } from './types.routes';

const pathname = 'products';
const pageName = 'Produtos';
const pageName2 = 'Produto';

export const productsRoutes: IRoutes[] = [
  {
    path: `/${pathname}`,
    children: <Product />,
    isPrivate: true,
    exact: true,
    pageName,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/create`,
    children: <ProductCreate />,
    isPrivate: true,
    exact: true,
    pageName: `Cadastrar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Cadastrar', link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/:id/edit`,
    children: <ProductEdit />,
    isPrivate: true,
    exact: true,
    pageName: `Atualizar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Editar', link: `/${pathname}` },
    ],
  },
];
