import { Avatar, Box, Chip, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FiCalendar, FiMoon, FiShield } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import DataTable from '../../../../shared/components/DataTable';
import { SearchForm } from '../../../../shared/components/Form/SearchForm';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useQueryPaginate } from '../../../../shared/hooks/reactQuery/useQueryPaginate';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { formatPrice } from '../../../../shared/utils/formatPrice';
import { ICustomData, IResponseData } from './types';

export function Product() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const [products, setProducts] = useState<ICustomData[]>([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [defaultSearch, setDefaultSearch] = useState('');

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    status,
    isFetching,
  } = useQueryPaginate<IResponseData>({
    key: ['products', search],
    url: `/admin/products`,
    params: `search=${search}`,
  });

  useEffect(() => {
    if (data?.pages) {
      const formatProducts: ICustomData[] = [];

      data.pages.forEach((group) => {
        setTotal(group.count);

        group.results.forEach((item) => {
          formatProducts.push({
            ...item,
            formattedPrice: (
              <Box whiteSpace="nowrap">
                R$ {formatPrice(Number(item.price))}
              </Box>
            ),
            formattedUnit: (
              <Box display="flex" alignItems="center">
                <Chip
                  title={item.unitMeasurement.name}
                  label={`${item.quantity} (${item.unitMeasurement.name.slice(
                    0,
                    2
                  )})`}
                  color="default"
                  size="small"
                />
              </Box>
            ),
            formattedType: (
              <Box display="flex" alignItems="center">
                <Chip
                  label={item.productTypes.name}
                  color="default"
                  size="small"
                />
              </Box>
            ),
            formattedDate: (
              <Box display="flex" alignItems="center" width="100%">
                <FiCalendar />
                <Box ml={1}>{moment(item.updated_at).format('DD/MM/YYYY')}</Box>
              </Box>
            ),
          });
        });
      });

      setProducts(formatProducts);
    }
  }, [data?.pages]);

  const handleCreate = useCallback(() => {
    history.push('/products/create');
  }, [history]);

  const handleDelete = useCallback(
    (product: IResponseData) => {
      api
        .delete(`/admin/products/${product.id}`)
        .then(() => {
          msgSuccess('Produto deletado com sucesso.');
          refetch();
        })
        .catch((err) => {
          msgError(err?.response?.data?.message);
        });
    },
    [msgError, msgSuccess, refetch]
  );

  const handleEdit = useCallback(
    (product: IResponseData) => {
      history.push(`/products/${product.id}/edit`, {
        product: {
          product_name: product.product_name,
          quantity: product.quantity,
          price: Number(product.price),
          unitMeasurement: product.unitMeasurement,
          productTypes: product.productTypes,
          id: product.id,
        },
      });
    },
    [history]
  );

  const handleSearch = useCallback((searchValue: string) => {
    setSearch(searchValue);
    setDefaultSearch(searchValue);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearch('');
    setDefaultSearch('');
    refetch();
  }, [refetch]);

  const tableHeadCells = {
    product_name: 'Produto',
    ...(isDesktop
      ? { formattedType: 'Tipo', formattedUnit: 'Quantidade' }
      : { formattedUnit: 'Qtd.' }),
    formattedPrice: 'Valor',
    ...(isDesktop ? { formattedDate: 'Última Atualização' } : {}),
  };

  return (
    <PageContainer>
      <SearchForm
        defaultValue={defaultSearch}
        loading={status === 'loading'}
        searchValue={(value) => handleSearch(value)}
        searchClear={handleSearchClear}
      />
      <Box pb={3} />

      <DataTable
        headCells={tableHeadCells}
        dataRows={products}
        dataTotal={total}
        isLoading={status === 'loading' || isFetching}
        errorMessage={error?.message}
        onEdit={(value) => handleEdit(value)}
        onDelete={(value) => handleDelete(value)}
        onCreate={handleCreate}
        onNextPage={fetchNextPage}
        isDisabledNextPage={!hasNextPage || isFetchingNextPage}
      />
    </PageContainer>
  );
}
