import * as yup from 'yup';

export const schema = yup.object().shape({
  product_name: yup
    .string()
    .required('O nome é obrigatório.')
    .max(190, 'No máximo 90 caracteres.'),
  quantity: yup.string().required('A quantidade é obrigatória.'),
  price: yup.string().required('O valor é obrigatório.'),
  unitMeasurement: yup
    .object({
      id: yup.number(),
      name: yup.string(),
    })
    .required('A unidade é obrigatório.'),
  productTypes: yup
    .object({
      id: yup.number(),
      name: yup.string(),
    })
    .required('O tipo é obrigatório.'),
});
