import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup
    .string()
    .required('O nome é obrigatório.')
    .max(90, 'No máximo 90 caracteres.'),
  email: yup
    .string()
    .email()
    .required('O e-mail é obrigatório.')
    .max(190, 'No máximo 190 caracteres.'),
  role: yup
    .object({
      id: yup.number(),
      name: yup.string(),
    })
    .required('O nome é obrigatório.'),
  password: yup
    .string()
    .required('A senha é obrigatória.')
    .min(6, 'No mínimo 6 caracteres.')
    .max(40, 'No máximo 40 caracteres.'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação incorreta'),
});
