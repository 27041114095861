import { Clear, Search as SearchIcon } from '@material-ui/icons';
import {
  Stack,
  InputAdornment,
  TextField,
  Box,
  MenuItem,
  Menu,
  Fade,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MouseEvent, useState } from 'react';

import Iconify from '../Iconify';

// ----------------------------------------------------------------------

type Props = {
  filterName: string;
  searchName: (value: string) => void;
  searchLabel: string;
  onFilterStatus?: (value: string, checked: boolean) => void;
  onFilterProduct?: (value: string, checked: boolean) => void;
  onFilterInterval?: (value: string, checked: boolean) => void;
  onFilterRole?: (value: string, checked: boolean) => void;
  isFilterSelect?: boolean;
};

export default function UserSearchbar({
  filterName,
  searchName,
  searchLabel,
  onFilterStatus,
  onFilterRole,
  onFilterProduct,
  onFilterInterval,
  isFilterSelect,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  const filter = onFilterStatus || onFilterRole || onFilterProduct;
  const signature = onFilterProduct && onFilterInterval;

  const handleOpenOptions = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ py: 2.5, px: 3 }}
    >
      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => searchName(event.target.value)}
        placeholder={searchLabel || 'Procurar...'}
        InputProps={{
          startAdornment: (
            <>
              <InputAdornment position="start">
                <SearchIcon color="primary" />

                {filter && (
                  <IconButton
                    aria-controls="menu-options-table"
                    aria-haspopup="true"
                    color="inherit"
                    style={{ position: 'absolute', right: '0' }}
                    onClick={(event) => handleOpenOptions(event)}
                  >
                    <Iconify
                      icon="mi:filter"
                      fontSize="24px"
                      color={
                        isFilterSelect ? theme.palette.primary.main : 'grey'
                      }
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                )}
              </InputAdornment>
            </>
          ),
        }}
      />

      <Menu
        id="menu-options-table"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        <MenuItem>
          {onFilterStatus && (
            <Accordion style={{ width: '100%' }}>
              <AccordionSummary>Situação</AccordionSummary>
              <AccordionDetails>
                <div>
                  <input
                    type="checkbox"
                    id="ativo"
                    name="status1"
                    value={signature ? 'active' : '1'}
                    onChange={(event) => {
                      onFilterStatus(event.target.value, event.target.checked);
                    }}
                  />
                  <label htmlFor="ativo">Ativo</label>
                </div>

                <div>
                  <input
                    type="checkbox"
                    id="bloqueado"
                    name="status0"
                    value={signature ? 'canceled' : '0'}
                    onChange={(event) => {
                      onFilterStatus(event.target.value, event.target.checked);
                    }}
                  />
                  <label htmlFor="bloqueado">
                    {signature ? 'Cancelado' : 'Bloqueado'}
                  </label>
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </MenuItem>

        <MenuItem>
          {onFilterRole && (
            <Accordion style={{ width: '100%' }}>
              <AccordionSummary>Função</AccordionSummary>
              <AccordionDetails>
                <div>
                  <input
                    type="checkbox"
                    id="admin"
                    name="role1"
                    value="1"
                    onChange={(event) => {
                      onFilterRole(event.target.value, event.target.checked);
                    }}
                  />
                  <label htmlFor="admin">Administrador</label>
                </div>

                <div>
                  <input
                    type="checkbox"
                    id="client"
                    name="role0"
                    value="2"
                    onChange={(event) => {
                      onFilterRole(event.target.value, event.target.checked);
                    }}
                  />
                  <label htmlFor="client">Cliente</label>
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </MenuItem>
        <MenuItem>
          {onFilterProduct && (
            <Accordion style={{ width: '100%' }}>
              <AccordionSummary>Assinatura</AccordionSummary>
              <AccordionDetails>
                <div>
                  <input
                    type="checkbox"
                    id="product1"
                    name="product1"
                    value="Plano Mensal"
                    onChange={(event) => {
                      onFilterProduct(event.target.value, event.target.checked);
                    }}
                  />
                  <label htmlFor="product1">Plano Mensal</label>
                </div>

                <div>
                  <input
                    type="checkbox"
                    id="product2"
                    name="product2"
                    value="Plano Anual"
                    onChange={(event) => {
                      onFilterProduct(event.target.value, event.target.checked);
                    }}
                  />
                  <label htmlFor="product2">Plano Anual</label>
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </MenuItem>

        <MenuItem>
          {onFilterInterval && (
            <Accordion style={{ width: '100%' }}>
              <AccordionSummary>Tipo de Cobrança</AccordionSummary>
              <AccordionDetails>
                <div>
                  <input
                    type="checkbox"
                    id="interval1"
                    name="interval1"
                    value="month"
                    onChange={(event) => {
                      onFilterInterval(
                        event.target.value,
                        event.target.checked
                      );
                    }}
                  />
                  <label htmlFor="interval1">Mensal</label>
                </div>

                <div>
                  <input
                    type="checkbox"
                    id="product2"
                    name="product2"
                    value="year"
                    onChange={(event) => {
                      onFilterInterval(
                        event.target.value,
                        event.target.checked
                      );
                    }}
                  />
                  <label htmlFor="product2">Anual</label>
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </MenuItem>
      </Menu>
    </Stack>
  );
}
