import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ReactPlayer from 'react-player';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { ICustomData, IForm } from '../types';
import { schema } from './schema';

export function VideoEdit() {
  const history = useHistory();
  const { state } = useLocation<{ video: ICustomData }>();
  const { msgSuccess, msgError } = useSnackMessages();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...state?.video,
    },
  });

  const handlePush = useCallback(() => {
    history.push('/videos');
  }, [history]);

  useEffect(() => {
    if (!state?.video?.id) {
      handlePush();
    }
  }, [state?.video?.id, handlePush]);

  const onSubmit = useCallback(
    ({ description, title, url }: IForm) => {
      const formatData = {
        description,
        title,
        url,
      };

      api
        .put(`/admin/videos/${state?.video.id}`, formatData)
        .then(() => {
          msgSuccess('Vídeo atualizado com sucesso!');
          handlePush();
        })
        .catch((err) => {
          msgError(err.response?.data?.message);
        });
    },
    [state?.video?.id, handlePush, msgSuccess, msgError]
  );

  return (
    <PageContainer component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" mb={3} width="100%">
        <Input
          help="Exemplo: https://youtube.com/watch..."
          name="url"
          control={control}
          error={errors}
          label="URL do vídeo"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
        />
        <Box mb={2} />
        <Input
          name="title"
          control={control}
          error={errors}
          label="Título do vídeo"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
        />
        <Box mb={2} />
        <Input
          name="description"
          multiline
          minRows={4}
          control={control}
          error={errors}
          label="Descrição"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
        />
      </Box>
      {/*
      {watch('url') && (
        <Box
          width="100%"
          display="flex"
          alignItems="flex-start"
          flexDirection={['column', 'row']}
          my={2}
        >
          <Box
            bgcolor="background.paper"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            maxWidth={400}
          >
            <ReactPlayer url={watch('url')} height={220} controls />
          </Box>

          <Box display="flex" flexDirection="column" mt={[2, 0]} pl={[0, 2]}>
            <Box fontSize="1.1rem" color="primary.main">
              {watch('title')}
            </Box>
            <Box mt={1} fontSize="0.88rem">
              {watch('description')}
            </Box>
          </Box>
        </Box>
      )} */}

      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          size="large"
        >
          Salvar
        </Button>
      </Box>
    </PageContainer>
  );
}
