import { Avatar, Box, Chip, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import DataTable from '../../../../shared/components/DataTable';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useQueryPaginate } from '../../../../shared/hooks/reactQuery/useQueryPaginate';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { ICustomData, IResponseData } from './types';

export function Notification() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const [products, setProducts] = useState<ICustomData[]>([]);
  const [total, setTotal] = useState(0);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    status,
    isFetching,
  } = useQueryPaginate<IResponseData>({
    key: 'messages',
    url: `/message/admin/list`,
  });

  useEffect(() => {
    if (data?.pages) {
      const formatProducts: ICustomData[] = [];

      data.pages.forEach((group) => {
        setTotal(group.count);

        group.results.forEach((item) => {
          formatProducts.push({
            ...item,
            formattedAvatar: (
              <Box display="flex" alignItems="center">
                <Avatar src={item.user.avatar_url} />
                <Box ml={1}>{item.user.name}</Box>
              </Box>
            ),
            formattedDate: (
              <Box display="flex" alignItems="center" width="100%">
                <FiCalendar />
                <Box ml={1}>{moment(item.created_at).format('DD/MM/YYYY')}</Box>
              </Box>
            ),
          });
        });
      });

      setProducts(formatProducts);
    }
  }, [data?.pages]);

  const handleCreate = useCallback(() => {
    history.push('/notifications/create');
  }, [history]);

  const handleDelete = useCallback(
    (product: IResponseData) => {
      api
        .delete(`/message/?id=${product.id}`)
        .then(() => {
          msgSuccess('Mensagem deletado com sucesso.');
          refetch();
        })
        .catch((err) => {
          msgError(err?.response?.data?.message);
        });
    },
    [msgError, msgSuccess, refetch]
  );

  const handleEdit = useCallback(
    (notification: IResponseData) => {
      history.push(`/notifications/${notification.id}/edit`, {
        notification: {
          message: notification.message,
          id: notification.id,
        },
      });
    },
    [history]
  );

  const tableHeadCells = {
    formattedAvatar: 'Responsável',
    message: 'Mensagem',
    ...(isDesktop ? { formattedDate: 'Data' } : {}),
  };

  return (
    <PageContainer>
      <DataTable
        headCells={tableHeadCells}
        dataRows={products}
        dataTotal={total}
        isLoading={status === 'loading' || isFetching}
        errorMessage={error?.message}
        onEdit={(value) => handleEdit(value)}
        onDelete={(value) => handleDelete(value)}
        onCreate={handleCreate}
        onNextPage={fetchNextPage}
        isDisabledNextPage={!hasNextPage || isFetchingNextPage}
      />
    </PageContainer>
  );
}
