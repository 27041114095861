import { Avatar, Box, Chip, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FiCalendar, FiMoon, FiShield } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import DataTable from '../../../../shared/components/DataTable';
import { SearchForm } from '../../../../shared/components/Form/SearchForm';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useQueryPaginate } from '../../../../shared/hooks/reactQuery/useQueryPaginate';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { formatPrice } from '../../../../shared/utils/formatPrice';
import { ICustomData, IResponseData } from './types';

export function Recipe() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const [recipes, setRecipes] = useState<ICustomData[]>([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [defaultSearch, setDefaultSearch] = useState('');

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    status,
    isFetching,
  } = useQueryPaginate<IResponseData>({
    key: ['recipes', search],
    url: `/admin/recipes`,
    params: `search=${search}`,
  });

  const handleEdit = useCallback(
    (recipe: IResponseData) => {
      history.push(`/recipes/${recipe.id}/edit`, {
        recipe: {
          ...recipe,
          formattedRecipeType: null,
          formattedImage: null,
          formattedDate: null,
          formattedCountProducts: null,
        },
      });
    },
    [history]
  );

  useEffect(() => {
    if (data?.pages) {
      const formatRecipes: ICustomData[] = [];

      data.pages.forEach((group) => {
        setTotal(group.count);

        group.results.forEach((item) => {
          formatRecipes.push({
            ...item,
            formattedRecipeType: (
              <Box display="flex" alignItems="center">
                <Chip
                  title={item.recipeType.name}
                  label={item.recipeType.name}
                  color="default"
                  size="small"
                />
              </Box>
            ),
            formattedCountProducts: (
              <Box display="flex" alignItems="center">
                <Chip
                  label={item.recipeHasProducts.length}
                  color="default"
                  size="small"
                />
              </Box>
            ),
            formattedDate: (
              <Box display="flex" alignItems="center" width="100%">
                <FiCalendar />
                <Box ml={1}>{moment(item.updated_at).format('DD/MM/YYYY')}</Box>
              </Box>
            ),
            formattedImage: (
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                onClick={() => handleEdit(item)}
                style={{ cursor: 'pointer' }}
              >
                <Avatar
                  src={item.image_url}
                  style={{ borderRadius: 8, height: 60, width: 60 }}
                />
              </Box>
            ),
          });
        });
      });

      setRecipes(formatRecipes);
    }
  }, [data?.pages, handleEdit]);

  const handleCreate = useCallback(() => {
    history.push('/recipes/create');
  }, [history]);

  const handleDelete = useCallback(
    (product: IResponseData) => {
      api
        .delete(`/admin/recipes/${product.id}`)
        .then(() => {
          msgSuccess('Ficha Técnica deletada com sucesso.');
          refetch();
        })
        .catch((err) => {
          msgError(err?.response?.data?.message);
        });
    },
    [msgError, msgSuccess, refetch]
  );

  const handleSearch = useCallback((searchValue: string) => {
    setSearch(searchValue);
    setDefaultSearch(searchValue);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearch('');
    setDefaultSearch('');
    refetch();
  }, [refetch]);

  const tableHeadCells = {
    formattedImage: '#',
    title: 'Título',
    ...(isDesktop ? { formattedRecipeType: 'Tipo' } : {}),
    formattedCountProducts: 'Qtd. Ingredientes',
    ...(isDesktop ? { formattedDate: 'Última Atualização' } : {}),
  };

  return (
    <PageContainer>
      <SearchForm
        defaultValue={defaultSearch}
        loading={status === 'loading'}
        searchValue={(value) => handleSearch(value)}
        searchClear={handleSearchClear}
      />
      <Box pb={3} />

      <DataTable
        headCells={tableHeadCells}
        dataRows={recipes}
        dataTotal={total}
        isLoading={status === 'loading' || isFetching}
        errorMessage={error?.message}
        onEdit={(value) => handleEdit(value)}
        onDelete={(value) => handleDelete(value)}
        onCreate={handleCreate}
        onNextPage={fetchNextPage}
        isDisabledNextPage={!hasNextPage || isFetchingNextPage}
      />
    </PageContainer>
  );
}
