import { Switch } from 'react-router-dom';

import { routes } from './config.routes';
import { Route } from './Route';

export function Router() {
  return (
    <Switch>
      {routes.map((route) => {
        return (
          <Route
            key={`${route.path}-router`}
            isPrivate={route.isPrivate}
            exact={route.exact}
            path={route.path}
            pageName={route.pageName}
            layout={route.layout}
            breadcrumb={route.breadcrumb}
            historyPush={route.push}
          >
            {route.children}
          </Route>
        );
      })}
    </Switch>
  );
}
