import { Notification } from '../modules/records/pages/Notification';
import { NotificationCreate } from '../modules/records/pages/Notification/Create';
import { NotificationEdit } from '../modules/records/pages/Notification/Edit';
import { IRoutes } from './types.routes';

const pathname = 'notifications';
const pageName = 'Notificações';
const pageName2 = 'Notificação';

export const notificationRoutes: IRoutes[] = [
  {
    path: `/${pathname}`,
    children: <Notification />,
    isPrivate: true,
    exact: true,
    pageName,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/create`,
    children: <NotificationCreate />,
    isPrivate: true,
    exact: true,
    pageName: `Nova ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Nova', link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/:id/edit`,
    children: <NotificationEdit />,
    isPrivate: true,
    exact: true,
    pageName: `Atualizar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Editar', link: `/${pathname}` },
    ],
  },
];
