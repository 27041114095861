import * as yup from 'yup';

export const schema = yup.object().shape({
  title: yup.string().required('Defina o nome da ficha técnica.'),
  description: yup.string(),
  quantity: yup.string().required('Campo obrigatório.'),
  type: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required('Selecione o tipo da ficha técnica.'),
});
