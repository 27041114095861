import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { InputCurrency } from '../../../../../shared/components/Form/Input/InputCurrency';
import { InputDatePicker } from '../../../../../shared/components/Form/Input/InputDatePicker';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { schema } from './schema';

export function AnnualPlanningCreate() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();
  const [totalDetails, setTotalDetails] = useState<{ id: number }[]>([
    { id: Date.now() },
  ]);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      event_date: '',
    },
    mode: 'onChange',
  });

  const handlePush = useCallback(() => {
    history.push('/scheduling-date');
  }, [history]);

  const onSubmit = useCallback(
    async ({ title, event_date, items }: any) => {
      console.log(title, event_date, items);

      try {
        const formatData = {
          title,
          event_date: event_date
            ? moment(new Date(event_date)).format('YYYY-MM-DD')
            : undefined,
          items: items?.map((item: any) => {
            return {
              ...item,
              start_date: item.start_date
                ? moment(new Date(item.start_date)).format('YYYY-MM-DD')
                : undefined,
              end_date: item.end_date
                ? moment(new Date(item.end_date)).format('YYYY-MM-DD')
                : undefined,
              value: item?.value
                ? item.value?.replace('R$', '').replace(',', '.') || '0'
                : 0,
            };
          }),
        };

        await api.post('/admin/annual-planning', formatData);
        console.log(event_date);
        msgSuccess('Planejamento anual cadastrado com sucesso!');

        handlePush();
      } catch (err: any) {
        msgError(err?.response?.data?.message);
      }
    },
    [msgError, msgSuccess, handlePush]
  );

  const handleAddDetails = () => {
    setTotalDetails((state) => [...state, { id: Date.now() }]);
  };

  const handleRemoveDetails = (id: number) => {
    setTotalDetails((state) => state.filter((item) => item.id !== id));
  };

  return (
    <PageContainer component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" gridGap={16} width="100%">
        <Box mb={2} color="primary.dark">
          Cabeçalho:
        </Box>
        <Input
          help="Insira um título de destaque para seu planejamento anual"
          name="title"
          control={control}
          error={errors}
          variant="outlined"
          label="Título do Evento"
          fullWidth
          required
          disabled={isSubmitting}
          autoFocus
        />
        <InputDatePicker
          name="event_date"
          label="Data do Evento"
          variant="outlined"
          control={control}
          error={errors}
          disabled={isSubmitting}
          required
        />
        <Box mb={2} />

        <Box mb={2} color="primary.dark">
          Detalhes:
        </Box>
        {totalDetails?.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <fieldset
                style={{
                  borderRadius: '4px',
                  borderColor: '#eee',
                  marginBottom: 16,
                }}
              >
                <Box display="flex" justifyContent="flex-end" pb={2}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleRemoveDetails(item.id)}
                    disabled={totalDetails.length <= 1}
                    style={{ opacity: totalDetails.length <= 1 ? 0.4 : 1 }}
                  >
                    Remover
                  </Button>
                </Box>

                <Input
                  help="Insira quem irá participar do seu evento Ex: (Clientes / funcionários)"
                  name={`items[${index}][who]`}
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Quem?"
                  fullWidth
                  disabled={isSubmitting}
                  style={{ marginBottom: '15px' }}
                />

                <InputDatePicker
                  name={`items[${index}][start_date]`}
                  label="Quando inicia?"
                  variant="outlined"
                  control={control}
                  error={errors}
                  disabled={isSubmitting}
                />

                <InputDatePicker
                  name={`items[${index}][end_date]`}
                  label="Quando termina?"
                  variant="outlined"
                  control={control}
                  error={errors}
                  disabled={isSubmitting}
                />

                <Input
                  help="Insira suas estratégias para sua data comemorativa / comercial"
                  name={`items[${index}][how]`}
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Como?"
                  fullWidth
                  disabled={isSubmitting}
                  style={{ marginBottom: '15px' }}
                />

                <InputCurrency
                  help="Insira o valor que você pretende gastar no evento"
                  name={`items[${index}][value]`}
                  type="text"
                  label="Quanto?"
                  variant="outlined"
                  control={control}
                  error={errors}
                  style={{ marginBottom: 0 }}
                  disabled={!!isSubmitting}
                />
                <Box mb={2} />

                <Input
                  help="Insira algumas observações adicionais do seu evento"
                  name={`items[${index}][additional_observations]`}
                  multiline
                  minRows={3}
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Observações Adicionais"
                  fullWidth
                  disabled={isSubmitting}
                  style={{ marginBottom: '15px' }}
                />
              </fieldset>
            </React.Fragment>
          );
        })}
      </Box>

      <Box width="100%" display="flex" justifyContent="stretch" pb={1}>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={handleAddDetails}
          disabled={isSubmitting}
          style={{
            width: '100%',
            opacity: isSubmitting ? 0.4 : 1,
            height: 56,
            borderStyle: 'dashed',
          }}
          styleMotion={{ width: '100%' }}
        >
          Adicionar mais detalhes
        </Button>
      </Box>
      <Box mb={4} />

      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          size="large"
        >
          Salvar
        </Button>
      </Box>
    </PageContainer>
  );
}
