import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { InputCurrency } from '../../../../../shared/components/Form/Input/InputCurrency';
import { InputPassword } from '../../../../../shared/components/Form/Input/InputPassword';
import { Select } from '../../../../../shared/components/Form/Select';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useQuery } from '../../../../../shared/hooks/reactQuery/useQuery';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { ICustomData, IForm } from '../types';
import { schema } from './schema';

type ISelect = {
  id: number;
  name: string;
};

type IResponseData = { product_types: ISelect[]; unit_measurements: ISelect[] };

export function ProductEdit() {
  const history = useHistory();
  const { state } = useLocation<{ product: ICustomData }>();
  const { msgSuccess, msgError } = useSnackMessages();

  const [productTypes, setProductTypes] = useState<ISelect[]>([]);
  const [unitMeasurements, setUnitMeasurements] = useState<ISelect[]>([]);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...state?.product,
    },
  });

  const { data, error } = useQuery<IResponseData>({
    key: 'productTypesAndUnit',
    url: `/products/types-and-units`,
  });

  useEffect(() => {
    if (data?.result?.product_types) {
      setProductTypes(data.result?.product_types);
      // setValue(
      //   'productTypes',
      //   data.result?.product_types.find(
      //     (current) => current.id === state.product.productTypes.id
      //   ) || data.result?.product_types?.[0]
      // );
      setUnitMeasurements(data.result?.unit_measurements);
      // setValue('unitMeasurement', data.result?.unit_measurements?.[0]);
    }

    if (error) {
      console.log(error.message);
    }
  }, [data?.result, setValue, error]);

  const handlePush = useCallback(() => {
    history.push('/products');
  }, [history]);

  useEffect(() => {
    if (!state?.product?.id) {
      handlePush();
    }
  }, [state?.product?.id, handlePush]);

  const onSubmit = useCallback(
    ({
      product_name,
      price,
      quantity,
      productTypes,
      unitMeasurement,
    }: IForm) => {
      const formatData = {
        product_name,
        price: price.replace('R$', '').replace(',', '.'),
        quantity,
        productTypes,
        unitMeasurement,
      };

      api
        .put(`/admin/products/${state?.product.id}`, formatData)
        .then(() => {
          msgSuccess('Produto atualizado com sucesso!');
          handlePush();
        })
        .catch((err) => {
          msgError(err.response?.data?.message);
        });
    },
    [state?.product?.id, handlePush, msgSuccess, msgError]
  );

  return (
    <PageContainer component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
        gridGap={24}
        mb={3}
      >
        <Input
          name="product_name"
          control={control}
          error={errors}
          label="Nome do produto"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
        />
        <Input
          name="quantity"
          type="number"
          control={control}
          error={errors}
          label="Medida"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
        />
        <InputCurrency
          name="price"
          control={control}
          error={errors}
          label="Valor do produto"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
        />
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
        gridGap={24}
        mb={3}
      >
        <Select
          options={unitMeasurements}
          name="unitMeasurement"
          control={control}
          error={errors}
          label="Unidade de medida"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
        />
        <Select
          options={productTypes}
          name="productTypes"
          control={control}
          error={errors}
          label="Tipo de produto"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
        />
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          size="large"
        >
          Salvar
        </Button>
      </Box>
    </PageContainer>
  );
}
