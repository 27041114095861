import { Banner } from '../modules/records/pages/Banner';
import { BannerCreate } from '../modules/records/pages/Banner/Create';
import { BannerEdit } from '../modules/records/pages/Banner/Edit';
import { IRoutes } from './types.routes';

const pathname = 'ads';
const pageName = 'Propagandas';
const pageName2 = 'Propaganda';

export const bannersRoutes: IRoutes[] = [
  {
    path: `/${pathname}`,
    children: <Banner />,
    isPrivate: true,
    exact: true,
    pageName,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/create`,
    children: <BannerCreate />,
    isPrivate: true,
    exact: true,
    pageName: `Nova Propaganda`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Nova Propaganda', link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/:id/edit`,
    children: <BannerEdit />,
    isPrivate: true,
    exact: true,
    pageName: `Atualizar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Atualizar Propaganda', link: `/${pathname}` },
    ],
  },
];
