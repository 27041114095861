import { User } from '../modules/accounts/pages/User';
import { UserCreate } from '../modules/accounts/pages/User/Create';
import { UserDetail } from '../modules/accounts/pages/User/Detail';
import { UserEdit } from '../modules/accounts/pages/User/Edit';
import { IRoutes } from './types.routes';

const pathname = 'users';
const pageName = 'Usuários';
const pageName2 = 'Usuário';

export const usersRoutes: IRoutes[] = [
  {
    path: `/${pathname}`,
    children: <User />,
    isPrivate: true,
    exact: true,
    pageName,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/create`,
    children: <UserCreate />,
    isPrivate: true,
    exact: true,
    pageName: `Cadastrar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Cadastrar', link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/:id/edit`,
    children: <UserEdit />,
    isPrivate: true,
    exact: true,
    pageName: `Atualizar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Editar', link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/:id`,
    children: <UserDetail />,
    isPrivate: true,
    exact: true,
    pageName: `Detalhes do ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Detalhes', link: `/${pathname}` },
    ],
  },
];
