/* eslint-disable @typescript-eslint/ban-types */
import {
  Box,
  BoxProps,
  CircularProgress,
  makeStyles,
  Paper,
  PaperProps,
  TextField,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { Clear, Search as SearchIcon } from '@material-ui/icons';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '../../Button';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    width: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    height: 56,
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    background: theme.palette.background.paper,
  },
  search: {
    border: 'none',
    borderWidth: 0,
    width: '100%',
    marginLeft: theme.spacing(1),
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderLeft: `1px solid ${theme.border.color}`,
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  searchButton: {
    height: '1rem',
  },
}));

interface SearchFormProps extends PaperProps {
  defaultValue?: string;
  loading: boolean;
  results?: number;
  searchValue(value: string): void;
  searchClear(): void;
  disabled?: boolean;
}

export function SearchForm({
  defaultValue,
  loading,
  results,
  searchValue,
  searchClear,
  disabled,
  ...rest
}: SearchFormProps) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(loading);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // setIsLoading(loading);
    if (defaultValue && inputRef.current) {
      inputRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const debounce = useCallback((func: Function, wait: number): Function => {
    let timeout: NodeJS.Timeout;

    return function executedFunction(...args: number[]) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }, []);

  const returnedFunction = debounce((event: any) => {
    /* Aqui podemos inserir a lógica
    | para realizar as buscas em
    |  nosso back-end.
    */
    if (event?.target?.value) {
      // setIsLoading(true);
      searchValue(event.target.value);
    }
  }, 800);

  const handleClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    searchClear();
  }, [searchClear]);

  return (
    <label htmlFor="test" className={classes.label}>
      <Paper className={classes.root} elevation={3} {...rest}>
        <SearchIcon color="primary" />

        <TextField
          id="test"
          type="text"
          name="search"
          placeholder="Pesquisar..."
          variant="outlined"
          className={classes.search}
          defaultValue={defaultValue}
          onKeyUp={(event) => returnedFunction(event)}
          inputRef={inputRef}
          disabled={disabled}
        />

        {inputRef.current && inputRef?.current?.value?.length > 0 && (
          <Button
            variant="text"
            style={{
              marginLeft: 1,
              height: 42,
              width: 42,
              padding: 0,
            }}
            disabled={isLoading}
            onClick={handleClear}
            className={classes.searchButton}
            whileTap={{ scale: 0.85 }}
          >
            <Tooltip
              title="Limpar campo de pesquisa"
              aria-label="Limpar campo de pesquisa"
            >
              {isLoading ? (
                <CircularProgress style={{ width: 20, height: 20 }} />
              ) : (
                <Clear style={{ width: 20, height: 20 }} />
              )}
            </Tooltip>
          </Button>
        )}
      </Paper>
    </label>
  );
}
