import { Avatar, Box, Chip, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FiCalendar, FiRefreshCw } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import DataTable from '../../../../shared/components/DataTable';
import { IconButton } from '../../../../shared/components/IconButton';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useQueryPaginate } from '../../../../shared/hooks/reactQuery/useQueryPaginate';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { ICustomData, IResponseData } from './types';

export function Banner() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const [posts, setPosts] = useState<ICustomData[]>([]);
  const [total, setTotal] = useState(0);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    status,
    isFetching,
  } = useQueryPaginate<IResponseData>({
    key: 'ads',
    url: `/admin/ads`,
  });

  const handleToggleStatus = useCallback(
    (status: boolean, ads: any) => {
      api
        .put(`/admin/ads/${ads?.id}`, {
          ...ads,
          status: !status,
        })
        .then(() => {
          msgSuccess('Situação da propaganda atualizada com sucesso!');
          refetch();
        })
        .catch((error) => {
          msgError(error?.response?.data?.message);
        });
    },
    [msgSuccess, msgError, refetch]
  );

  useEffect(() => {
    if (data?.pages) {
      const formatData: ICustomData[] = [];

      data.pages.forEach((group) => {
        setTotal(group.count);

        group.results.forEach((item) => {
          formatData.push({
            ...item,
            formattedImage: (
              <Box
                display="flex"
                alignItems="center"
                maxWidth={120}
                position="relative"
              >
                {item?.url_redirect ? (
                  <a href={item.url_redirect} target="_blank" rel="noreferrer">
                    <Avatar
                      src={item?.image_url}
                      style={{ width: '100%', height: '100%', borderRadius: 4 }}
                    />
                  </a>
                ) : (
                  <Avatar
                    src={item?.image_url}
                    style={{ width: '100%', height: '100%', borderRadius: 4 }}
                  />
                )}
              </Box>
            ),
            formattedDate: (
              <Box display="flex" alignItems="center" width="100%">
                <FiCalendar />
                <Box ml={1}>{moment(item.created_at).format('DD/MM/YYYY')}</Box>
              </Box>
            ),
            customStatus: (
              <Box display="flex" alignItems="center">
                <Chip
                  label={item?.status ? 'Ativo' : 'Inativo'}
                  color={item?.status ? 'primary' : 'default'}
                  size="small"
                  disabled={!item?.status}
                />
                <IconButton
                  onClick={() => handleToggleStatus(!!item?.status, item)}
                >
                  <FiRefreshCw />
                </IconButton>
              </Box>
            ),
            customType: (
              <Box display="flex" alignItems="center">
                <Chip
                  label={item?.type === 'footer' ? 'Rodapé' : 'Tela Cheia'}
                  color="default"
                  size="small"
                />
              </Box>
            ),
          });
        });
      });

      setPosts(formatData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pages]);

  const handleCreate = useCallback(() => {
    history.push('/ads/create');
  }, [history]);

  const handleDelete = useCallback(
    (ads: IResponseData) => {
      api
        .delete(`/admin/ads/${ads.id}`)
        .then(() => {
          msgSuccess('Propaganda deletada com sucesso.');
          refetch();
        })
        .catch((err) => {
          msgError(err?.response?.data?.message);
        });
    },
    [msgError, msgSuccess, refetch]
  );

  const handleEdit = useCallback(
    (ads: IResponseData) => {
      history.push(`/ads/${ads.id}/edit`, {
        ads: {
          title: ads.title,
          url_redirect: ads.url_redirect,
          type: ads.type,
          status: ads.status,
          image: ads.image,
          image_url: ads.image_url,
          id: ads.id,
        },
      });
    },
    [history]
  );

  const tableHeadCells = {
    formattedImage: '#',
    title: 'Título',
    customStatus: 'Situação',
    ...(isDesktop ? { customType: 'Tipo', formattedDate: 'Data' } : {}),
  };

  return (
    <PageContainer>
      <DataTable
        headCells={tableHeadCells}
        dataRows={posts}
        dataTotal={total}
        isLoading={status === 'loading' || isFetching}
        errorMessage={error?.message}
        onEdit={(value) => handleEdit(value)}
        onDelete={(value) => handleDelete(value)}
        onCreate={handleCreate}
        onNextPage={fetchNextPage}
        isDisabledNextPage={!hasNextPage || isFetchingNextPage}
      />
    </PageContainer>
  );
}
