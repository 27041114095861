import { Box, BoxProps } from '@material-ui/core';

type PageContainerProps = BoxProps;

export function PageContainer({ children, ...rest }: PageContainerProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth="860px"
      marginX="auto"
      {...rest}
    >
      {children}
    </Box>
  );
}
