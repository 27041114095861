import { Me } from '../modules/accounts/pages/Me';
import { ForgotPassword } from '../modules/authentication/pages/ForgotPassword';
import { ResetPassword } from '../modules/authentication/pages/ResetPassword';
import { SignIn } from '../modules/authentication/pages/SignIn';
import { Home } from '../modules/home/pages/Home';
import { NotFound } from '../modules/home/pages/NotFound';
import { bannersRoutes } from './banners.routes';
import { blogRoutes } from './blog.routes';
import { notificationRoutes } from './notifications.routes';
import { productsRoutes } from './products.routes';
import { recipesRoutes } from './recipes.routes';
import { schedulingDateRoutes } from './schedulingDate.routes';
import { signaturesRoutes } from './signatures.routes';
import { IRoutes } from './types.routes';
import { usersRoutes } from './users.routes';
import { videosRoutes } from './videos.routes';

export const publicRoutes: IRoutes[] = [
  {
    path: '/sign-in',
    children: <SignIn />,
  },
  {
    path: '/forgot-password',
    children: <ForgotPassword />,
  },
  {
    path: '/reset-password/:token',
    children: <ResetPassword />,
  },
];

export const protectedRoutes: IRoutes[] = [
  {
    path: '/',
    children: <Home />,
    isPrivate: true,
    exact: true,
    pageName: 'Painel de Controle',
  },
  {
    path: '/profile',
    children: <Me />,
    isPrivate: true,
    exact: true,
    pageName: 'Meu Perfil',
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: 'Perfil', link: '/profile' },
    ],
  },
];

export const routes: IRoutes[] = [
  ...publicRoutes,
  ...protectedRoutes,
  ...usersRoutes,
  ...productsRoutes,
  ...signaturesRoutes,
  ...recipesRoutes,
  ...notificationRoutes,
  ...schedulingDateRoutes,
  ...videosRoutes,
  ...blogRoutes,
  ...bannersRoutes,
  {
    path: '/*',
    children: <NotFound />,
    isPrivate: true,
  },
];
