import {
  Box,
  TextField,
  TextFieldProps,
  Typography,
  makeStyles,
  Theme,
  Popover,
} from '@material-ui/core';
import {
  ReportProblemOutlined as Error,
  Help as HelpIcon,
} from '@material-ui/icons';
import React from 'react';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) => ({
  inputBase: {
    '& .MuiInputBase-input': {
      paddingRight: 40,
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: 300,
  },
}));

type Props = TextFieldProps & {
  control: any;
  name: string;
  error?: any;
  help?: string;
};

function Input({ control, name, error = undefined, help, ...rest }: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box width="full" display="flex" flexDirection="column">
      <Controller
        name={name}
        control={control}
        rules={{ required: rest?.required }}
        render={({ field }) => (
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            position="relative"
          >
            <TextField
              className={help ? classes.inputBase : ''}
              {...field}
              {...rest}
            />

            {help && (
              <>
                <Box
                  position="absolute"
                  right={7}
                  top="25%"
                  color="inherit"
                  aria-owns={open ? `${name}-mouse-over-popover` : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <HelpIcon color="inherit" style={{ opacity: 0.6 }} />
                </Box>
                <Popover
                  id={`${name}-mouse-over-popover`}
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography color="primary" variant="inherit">
                    {help}
                  </Typography>
                </Popover>
              </>
            )}
          </Box>
        )}
      />
      {name && error?.[name]?.message && (
        <Typography
          component="p"
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error[name]?.message}
        </Typography>
      )}
    </Box>
  );
}

export { Input };
