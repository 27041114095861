import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import moment from 'moment';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { InputCurrency } from '../../../../../shared/components/Form/Input/InputCurrency';
import { InputDatePicker } from '../../../../../shared/components/Form/Input/InputDatePicker';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { ICustomData } from '../types';
import { schema } from './schema';

export function AnnualPlanningEdit() {
  const history = useHistory();
  const { state } = useLocation<{ annualPlanning: ICustomData }>();
  const { msgSuccess, msgError } = useSnackMessages();
  const annualPlanning = useMemo(() => {
    return state?.annualPlanning;
  }, [state?.annualPlanning]);

  const [totalDetails, setTotalDetails] = useState<{ id: number }[]>(() => {
    return (
      state?.annualPlanning.detailsAnualPlannings?.map((item) => ({
        id: item.id,
      })) || [{ id: Date.now() }]
    );
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: state?.annualPlanning.title,
      event_date: state?.annualPlanning?.event_date,
      items: state?.annualPlanning.detailsAnualPlannings,
    },
    mode: 'onChange',
  });

  const handlePush = useCallback(() => {
    history.push('/scheduling-date');
  }, [history]);

  useEffect(() => {
    if (!state?.annualPlanning?.id) {
      handlePush();
    }
  }, [state?.annualPlanning?.id, handlePush]);

  const onSubmit = useCallback(
    async ({ title, event_date, items }: any) => {
      const filterItems = items?.filter((item: any) => {
        const findDetails = totalDetails.find(
          (detail) => detail.id === item.id
        );
        const findOldItems = annualPlanning.detailsAnualPlannings.find(
          (oldItem) => oldItem.id === item.id
        );

        if (!findOldItems || findDetails) return true;

        return false;
      });

      try {
        const formatData = {
          title,
          event_date: event_date
            ? moment(new Date(event_date)).format('YYYY-MM-DD')
            : undefined,
          items: filterItems?.map((item: any) => {
            const value = `${item?.value || '0.00'}`;

            const parseValue = value
              ? value.replace('R$', '').replace(',', '.') || '0'
              : 0;

            return {
              ...item,
              start_date: item?.start_date
                ? moment(new Date(item.start_date)).format('YYYY-MM-DD')
                : undefined,
              end_date: item?.end_date
                ? moment(new Date(item.end_date)).format('YYYY-MM-DD')
                : undefined,
              value: parseValue,
            };
          }),
        };

        await api.put(
          `/admin/annual-planning/${state?.annualPlanning.id}`,
          formatData
        );
        msgSuccess('Planejamento atualizado com sucesso!');

        history.push('/scheduling-date');
      } catch (err: any) {
        console.log(err);
        msgError(err?.response?.data?.message || err?.response?.data?.error);
      }
    },
    [
      history,
      msgError,
      msgSuccess,
      totalDetails,
      state?.annualPlanning.id,
      annualPlanning.detailsAnualPlannings,
    ]
  );

  const handleAddDetails = () => {
    setTotalDetails((state) => [...state, { id: Date.now() }]);
  };

  const handleRemoveDetails = (id: number) => {
    setTotalDetails((state) => state.filter((item) => item.id !== id));
  };

  return (
    <PageContainer component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" width="100%">
        <Box mb={2} color="primary.dark">
          Cabeçalho:
        </Box>
        <Input
          name="title"
          control={control}
          error={errors}
          variant="outlined"
          label="Titulo do Evento"
          fullWidth
          required
          disabled={isSubmitting}
        />
        <Box mb={2} />
        <InputDatePicker
          name="event_date"
          label="Data do evento"
          variant="outlined"
          control={control}
          error={errors}
          disabled={isSubmitting}
          required
        />
        <Box mb={3} />

        <Box mb={2} color="primary.dark">
          Detalhes:
        </Box>
        {totalDetails?.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <fieldset
                style={{
                  borderRadius: '4px',
                  borderColor: '#eee',
                  marginBottom: 16,
                }}
              >
                <Box display="flex" justifyContent="flex-end" pb={2}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleRemoveDetails(item.id)}
                    disabled={isSubmitting || totalDetails.length <= 1}
                    style={{
                      opacity:
                        isSubmitting || totalDetails.length <= 1 ? 0.4 : 1,
                    }}
                  >
                    Remover
                  </Button>
                </Box>

                <Input
                  help="Insira quem irá participar do seu evento Ex: (Clientes / funcionários)"
                  name={`items[${index}][who]`}
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Quem?"
                  fullWidth
                  disabled={isSubmitting}
                  style={{ marginBottom: '15px' }}
                />

                <InputDatePicker
                  name={`items[${index}][start_date]`}
                  label="Quando inicia?"
                  variant="outlined"
                  control={control}
                  error={errors}
                  disabled={isSubmitting}
                />

                <InputDatePicker
                  name={`items[${index}][end_date]`}
                  label="Quando termina?"
                  variant="outlined"
                  control={control}
                  error={errors}
                  disabled={isSubmitting}
                />

                <Input
                  help="Insira suas estratégias para sua data comemorativa / comercial"
                  name={`items[${index}][how]`}
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Como?"
                  fullWidth
                  disabled={isSubmitting}
                  style={{ marginBottom: '15px' }}
                />

                <InputCurrency
                  help="Insira o valor que você pretende gastar no evento"
                  name={`items[${index}][value]`}
                  type="text"
                  label="Quanto?"
                  variant="outlined"
                  control={control}
                  error={errors}
                  style={{ marginBottom: 0 }}
                  disabled={isSubmitting}
                />
                <Box mb={2} />

                <Input
                  help="Insira algumas observações adicionais do seu evento"
                  name={`items[${index}][additional_observations]`}
                  multiline
                  minRows={3}
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Observações Adicionais"
                  fullWidth
                  disabled={isSubmitting}
                  style={{ marginBottom: '15px' }}
                />
              </fieldset>
            </React.Fragment>
          );
        })}
      </Box>

      <Box width="100%" display="flex" justifyContent="stretch" pb={1}>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={handleAddDetails}
          disabled={isSubmitting}
          style={{
            width: '100%',
            opacity: isSubmitting ? 0.4 : 1,
            height: 56,
            borderStyle: 'dashed',
          }}
          styleMotion={{ width: '100%' }}
        >
          Adicionar mais detalhes
        </Button>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          size="large"
        >
          Salvar
        </Button>
      </Box>
    </PageContainer>
  );
}
