import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Edit } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { Input } from '../../../../shared/components/Form/Input';
import { InputDatePicker } from '../../../../shared/components/Form/Input/InputDatePicker';
import { InputPassword } from '../../../../shared/components/Form/Input/InputPassword';
import { Modal } from '../../../../shared/components/Modal';
import { useModalYourRegistration } from '../../../../shared/hooks/useModalYourRegistration';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { useUserAuthenticated } from '../../../../shared/hooks/useUserAuthenticated';
import { api } from '../../../../shared/services/apiClient';
import { AvatarUpload } from '../AvatarUpload';
import { FormValues } from './types';

type City = {
  id: number;
  name: string;
  state?: string;
};

const schema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório.'),
  email: yup.string().required('O e-mail é obrigatório.'),
  phone: yup.string(),
  city_id: yup.string(),
  complement: yup.string(),
  birthday: yup.string().nullable(),
  password: yup.string(),
});

export function YourRegistration() {
  const { msgSuccess, msgError } = useSnackMessages();
  const { isOpen, onClose, yourRegistration, cities } =
    useModalYourRegistration();
  const { refreshUser } = useUserAuthenticated();

  const [loading, setLoading] = useState(true);
  const [onEdit, setOnEdit] = useState(false);
  const [selectDefaultCity, setSelectDefaultCity] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const setDefaultValues = useCallback(() => {
    setValue('name', yourRegistration?.name || '');
    setValue('email', yourRegistration.email);
    setValue(
      'birthday',
      yourRegistration?.profile?.birthday
        ? moment(new Date(yourRegistration.profile.birthday)).format(
            'YYYY-MM-DD'
          )
        : null
    );
    setValue('phone', yourRegistration?.profile?.phone || undefined);
    setValue('city_id', yourRegistration?.address?.city_id || undefined);
    setValue('complement', yourRegistration?.address?.complement || undefined);

    let selectCity: City | undefined;

    if (yourRegistration?.address?.city_id && !!cities.length) {
      selectCity = cities.find(
        (city) => city.id === yourRegistration.address?.city_id
      );
    }

    if (!selectCity) return;

    setSelectDefaultCity(selectCity);
  }, [yourRegistration, setValue, cities]);

  useEffect(() => {
    if (yourRegistration) {
      setLoading(true);

      setDefaultValues();

      setLoading(false);
    }
  }, [yourRegistration, setDefaultValues]);

  const onSubmit = useCallback(
    async ({
      name,
      email,
      phone,
      birthday,
      city_id,
      complement,
      password,
    }: FormValues) => {
      setLoading(true);

      try {
        const formatData = {
          name,
          email,
          phone,
          birthday: birthday
            ? moment(new Date(birthday)).format('YYYY-MM-DD')
            : undefined,
          complement,
          address_id: yourRegistration?.profile?.address_id,
          city_id,
          password,
        };

        await api.post('/profile', formatData);

        msgSuccess('Perfil atualizado com sucesso!');

        refreshUser();

        setLoading(false);
        setOnEdit(false);

        onClose();
      } catch (err: any) {
        setLoading(false);
        msgError(err?.response?.data?.message);
      }
    },
    [msgError, msgSuccess, onClose, yourRegistration, refreshUser]
  );

  const handleClose = () => {
    setOnEdit(false);
    setDefaultValues();
    onClose();
  };

  return (
    <Modal title="Seu Cadastro" open={isOpen} onClose={handleClose}>
      {!onEdit && (
        <Box
          mb={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            startIcon={<Edit />}
            autoFocus
            size="large"
            color="primary"
            variant="outlined"
            onClick={() => setOnEdit(true)}
          >
            Clique aqui para editar
          </Button>
        </Box>
      )}
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          mb={4}
          bgcolor="#fbcdc65c"
          borderRadius={8}
          p={2}
        >
          {onEdit && (
            <Box
              color="primary.dark"
              textAlign="center"
              bgcolor="#fa9c8b30"
              borderRadius={8}
              p={1}
              mb={1}
            >
              Clique no botão abaixo (com icone de câmera) para alterar a foto
              de perfil.
            </Box>
          )}
          <motion.div whileTap={{ scale: onEdit ? 0.95 : 1 }}>
            <AvatarUpload isDisabled={!onEdit} />
          </motion.div>
        </Box>

        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          {isOpen && yourRegistration && (
            <Box display="flex" flexDirection="column" width="100%">
              <Input
                name="name"
                control={control}
                error={errors}
                variant="outlined"
                label="Seu nome"
                fullWidth
                required
                disabled={loading || !onEdit}
              />
              <Box mb={2} />

              <Input
                name="email"
                type="email"
                control={control}
                error={errors}
                variant="outlined"
                label="Seu e-mail"
                fullWidth
                required
                disabled={loading || !onEdit}
              />
              <Box mb={2} />

              <Input
                name="phone"
                type="tel"
                control={control}
                error={errors}
                variant="outlined"
                label="Telefone"
                fullWidth
                disabled={loading || !onEdit}
              />
              <Box mb={2} />

              <InputDatePicker
                name="birthday"
                label="Data de aniversário"
                variant="outlined"
                control={control}
                error={errors}
                disabled={loading || !onEdit}
              />
              <Box mb={2} />

              {!!cities.length && (
                <Box display="flex" flexDirection="column" width="100%" mt={1}>
                  <Box fontSize="0.85rem" color="secondary.light" mb={1}>
                    Endereço
                  </Box>
                  <Autocomplete
                    style={{ marginBottom: 16 }}
                    id="auto-select-city_id"
                    options={cities}
                    defaultValue={selectDefaultCity}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    disabled={loading || !onEdit}
                    onChange={(
                      event: any,
                      selectedValue: { id: number; name: string } | null
                    ) => {
                      if (selectedValue) {
                        setValue('city_id', selectedValue.id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cidade"
                        variant="outlined"
                        // {...register('city_id')}
                        required
                      />
                    )}
                  />

                  <Input
                    name="complement"
                    type="text"
                    control={control}
                    error={errors}
                    variant="outlined"
                    label="Complemento"
                    fullWidth
                    disabled={loading || !onEdit}
                  />
                  <Box mb={2} />
                </Box>
              )}

              <Box display="flex" flexDirection="column" width="100%" mt={1}>
                <Box fontSize="0.85rem" color="secondary.light" mb={1}>
                  Acesso ao APP
                </Box>
                <InputPassword
                  name="password"
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Nova senha"
                  disabled={loading || !onEdit}
                  autoComplete="new-password"
                />
              </Box>
            </Box>
          )}

          {!yourRegistration && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <CircularProgress />
              <span>Aguarde, carregando...</span>
            </Box>
          )}

          <DialogActions style={{ padding: 0 }}>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              gridGap={8}
              mt={4}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                size="large"
              >
                {onEdit ? 'Cancelar' : 'Fechar'}
              </Button>
              {onEdit && (
                <Button
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                >
                  Salvar
                </Button>
              )}
            </Box>
          </DialogActions>
        </Box>
      </DialogContent>
    </Modal>
  );
}
