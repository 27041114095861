import { Box, TextField, TextFieldProps, Typography } from '@material-ui/core';
import { ReportProblemOutlined as Error } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';

type SelectOption = {
  id: number;
  name: string;
};

type Props = TextFieldProps & {
  options: SelectOption[];
  control: any;
  name: string;
  error?: any;
  help?: string;
  disabled?: boolean;
  getSelectValue?: (value: SelectOption) => void;
  defaultValue?: any;
};

function Select({
  options = [],
  control,
  name,
  error = undefined,
  help,
  disabled,
  getSelectValue,
  defaultValue,
  ...rest
}: Props) {
  if (!options.length) return <></>;

  const handleSelectValue = (value: SelectOption) => {
    if (!getSelectValue) return;
    getSelectValue(value);
  };

  return (
    <Box width="full" display="flex" flexDirection="column">
      <Controller
        name={name}
        control={control}
        rules={{ required: rest?.required }}
        render={({ field }) => (
          <Autocomplete
            id={`auto-select-${name}`}
            options={options}
            value={field.value || defaultValue}
            defaultValue={field.value || defaultValue}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            disabled={disabled}
            onChange={(
              _,
              selectedValue: { id: number; name: string } | null
            ) => {
              if (selectedValue) {
                field.onChange(selectedValue);
                handleSelectValue(selectedValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                {...rest}
                disabled={disabled}
                error={!!error?.[name]?.message}
              />
            )}
          />
        )}
      />
      {name && error?.[name]?.message && (
        <Typography
          component="p"
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error[name]?.message}
        </Typography>
      )}
    </Box>
  );
}

export { Select };
