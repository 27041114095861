import {
  AppBar,
  Avatar,
  Box,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ENVIRONMENT } from '../../../config/environment';
import { menus } from '../../../config/menu';
import { logoPageAuth } from '../../assets/logoBase64';
import { useUserAuthenticated } from '../../hooks/useUserAuthenticated';
import { Flex } from '../Flex';
import { Footer } from '../Footer';
import { IconButton } from '../IconButton';
import { MenuProfile } from './MenuProfile';
import { StyledBadge, useStyles } from './styles';

interface TopNavigationProps {
  signOut: () => void;
}

export function TopNavigation(props: TopNavigationProps) {
  const classes = useStyles();
  const history = useHistory();
  const isDesktop = useMediaQuery('(min-width:700px)');
  const { user } = useUserAuthenticated();
  const { pathname } = useLocation();

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const { signOut } = props;

  useEffect(() => {
    setOpen(isDesktop);
  }, [isDesktop]);

  function handlePush(route: string) {
    history.push(route);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          {open && !isDesktop && (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          )}

          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            className={classes.title}
            noWrap
            style={{ textAlign: 'center' }}
          >
            {!isDesktop && (
              <>
                <img
                  src={logoPageAuth}
                  alt="Logo"
                  width="24px"
                  style={{ marginRight: 8 }}
                />
                Bolo no Bolso
              </>
            )}
          </Typography>

          <Flex flexGrow={0} justifyContent="flex-end">
            <MenuProfile signOut={signOut} handlePush={handlePush} />
          </Flex>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Box className={classes.toolbarLeft}>
          {isDesktop && (
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={1}
              borderRadius={4}
              bgcolor="#f89583a3"
              boxShadow="0 0 8px #c1766a"
            >
              <Box
                borderRadius="50%"
                bgcolor="#FFF"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={42}
                width={42}
                mr={1}
              >
                <img src={logoPageAuth} alt="Logo" width="28px" />
              </Box>
              <Typography variant="h6" style={{ color: '#FFFFFF' }}>
                Bolo no Bolso
              </Typography>
            </Box>
          )}
        </Box>

        <Divider />

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          py={[3, 4]}
          onClick={() => history.push('/profile')}
          style={{ cursor: 'pointer' }}
        >
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <Avatar
              alt={user?.name}
              src={user?.avatar ? user?.avatar_url : undefined}
              className={classes.avatar}
            />
          </StyledBadge>
          {open && (
            <>
              <Box>Bem vindo,</Box>
              <Typography noWrap style={{ maxWidth: 200 }}>
                Admin. {user?.name}
              </Typography>
            </>
          )}
        </Box>

        <Divider />

        <List>
          {menus.map((item) => (
            <React.Fragment key={item.name}>
              {item?.divider && (
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  mt={1}
                  pb={1}
                >
                  <Box
                    display="block"
                    height="1px"
                    width="100%"
                    bgcolor="#eee"
                  />
                  <Box px={1} fontSize="0.775rem" color="secondary.main">
                    Carga inicial
                  </Box>
                  <Box
                    display="block"
                    height="1px"
                    width="100%"
                    bgcolor="#eee"
                  />
                </Box>
              )}

              {!item?.divider && !item?.dividerEnd && (
                <ListItem button onClick={() => handlePush(item.link)}>
                  <ListItemIcon
                    className={clsx(
                      pathname === item?.link && classes.iconMenuSelect
                    )}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    className={clsx(
                      pathname === item?.link && classes.textMenuSelect
                    )}
                    primary={item.name}
                  />
                </ListItem>
              )}

              {item?.dividerEnd && (
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  borderBottom="1px solid #eee"
                  mb={1}
                  pb={1}
                />
              )}
            </React.Fragment>
          ))}
        </List>
        {open && (
          <Footer mt={3} mb={1} version={ENVIRONMENT.REACT_APP_VERSION} />
        )}
      </Drawer>
    </>
  );
}
