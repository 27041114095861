/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  TextField,
  TextFieldProps,
  Typography,
  makeStyles,
  Theme,
  Popover,
} from '@material-ui/core';
import {
  ReportProblemOutlined as Error,
  Help as HelpIcon,
} from '@material-ui/icons';
import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme: Theme) => ({
  inputBase: {
    '& .MuiInputBase-input': {
      paddingRight: 40,
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

type Props = TextFieldProps & {
  control: any;
  name: string;
  label?: string;
  error?: any;
  variant: 'outlined' | 'filled' | 'standard';
  style?: React.CSSProperties;
  required?: boolean;
  disabled?: boolean;
  help?: string;
  defaultValue?: string | number;
};

export const InputCurrency: React.FC<Props> = ({
  control,
  name,
  label,
  error,
  variant,
  style,
  help,
  required = false,
  disabled = false,
  defaultValue,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ marginBottom: 15, ...style }}
    >
      <Box display="flex" width="100%" position="relative">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <NumberFormat
                defaultValue={defaultValue}
                value={field.value}
                customInput={TextField}
                name={name}
                label={label}
                variant={variant}
                prefix="R$"
                type="text"
                decimalSeparator=","
                // onValueChange={({ value: v }) =>
                //   // eslint-disable-next-line prettier/prettier
                //   console.log({ target: { name, value: v } })}
                onChange={field.onChange}
                style={{ width: '100%' }}
                error={!!error[name]?.message}
                required={required}
                disabled={disabled}
                className={help ? classes.inputBase : ''}
              />

              {help && (
                <>
                  <Box
                    position="absolute"
                    right={7}
                    top="25%"
                    color="inherit"
                    aria-owns={open ? `${name}-mouse-over-popover` : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    <HelpIcon color="inherit" style={{ opacity: 0.6 }} />
                  </Box>
                  <Popover
                    id={`${name}-mouse-over-popover`}
                    className={classes.popover}
                    classes={{
                      paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography color="primary" variant="inherit">
                      {help}
                    </Typography>
                  </Popover>
                </>
              )}
            </>
          )}
        />
      </Box>
      {name && error[name]?.message && (
        <Typography
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error[name]?.message}
        </Typography>
      )}
    </Box>
  );
};
