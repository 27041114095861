import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';

import { PageContainer } from '../../../../shared/components/PageContainer';
import { useQuery } from '../../../../shared/hooks/reactQuery/useQuery';
import { DonutChart } from '../../components/DonutChart';

type Series = string[] | number[];

type IResponseData = {
  results: {
    notifications: number;
    products: number;
    recipes: number;
    signatures: { active: number; inactive: number };
    users: { active: number; inactive: number };
  };
};

function Home() {
  const [users, setUsers] = useState<Series>([0, 0]);
  const [signatures, setSignatures] = useState<Series>([0, 0]);
  const [products, setProducts] = useState<Series>([0]);
  const [recipes, setRecipes] = useState<Series>([0]);
  const [messages, setMessages] = useState<Series>([0]);

  const { data } = useQuery<IResponseData>({
    key: 'charts',
    url: `/charts`,
  });

  useEffect(() => {
    if (data?.result) {
      // console.log(data.result);
      setUsers([
        data.result?.results?.users?.active || 0,
        data.result?.results?.users?.inactive || 0,
      ]);
      setSignatures([
        data.result?.results?.signatures?.active || 0,
        data.result?.results?.signatures?.inactive || 0,
      ]);
      setProducts([data.result?.results?.products || 0]);
      setRecipes([data.result?.results?.recipes || 0]);
      setMessages([data.result?.results?.notifications || 0]);
    }
  }, [data]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <Box
        display="grid"
        gridTemplateColumns={['1fr', '1fr 1fr']}
        justifyContent="space-between"
        gridGap={16}
        mb={2}
      >
        <DonutChart title="Usuários" series={users} />

        <DonutChart title="Assinaturas" series={signatures} />

        <DonutChart title="Produtos" labels={['Total']} series={products} />

        <DonutChart
          title="Fichas Técnica"
          labels={['Quantidade']}
          series={recipes}
        />
      </Box>
      <DonutChart
        title="Notificações"
        labels={['Enviadas']}
        series={messages}
      />
    </PageContainer>
  );
}

export { Home };
