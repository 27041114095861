import { Box, CircularProgress, Fade, Menu, MenuItem } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import React, { MouseEvent, useMemo } from 'react';
import { IconType } from 'react-icons';
import {
  FiChevronDown,
  FiEdit,
  FiEye,
  FiMoreVertical,
  FiTrash,
} from 'react-icons/fi';

import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { NoData } from '../NoData';
import { useStylesDataTable, useToolbarStyles } from './styles';

type DataTableHead = { [key: string]: string };

interface HeadCell {
  id: string;
  label: string | number;
  align: 'right' | 'left';
}

interface EnhancedTableProps {
  // eslint-disable-next-line react/no-unused-prop-types
  classes?: ReturnType<typeof useStylesDataTable>;
  headCells: DataTableHead;
  isOptions?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { headCells, isOptions = false } = props;

  const customHeadCells: HeadCell[] = Object.keys(headCells).map(
    (cell, index) => {
      return {
        id: `${headCells[cell]}-${index}`,
        align: 'left',
        label: headCells[cell],
      };
    }
  );

  return (
    <TableHead>
      <TableRow>
        {customHeadCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding="normal">
            {headCell.label}
          </TableCell>
        ))}
        {isOptions && <TableCell padding="normal" />}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  onClick?: () => void;
  isLoading: boolean;
}

const EnhancedTableToolbar = ({
  onClick,
  isLoading,
}: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx([classes.root], [!onClick ? classes.minHeight : ''])}
    >
      {onClick && (
        <>
          <Button
            aria-label="add-create"
            variant="outlined"
            size="medium"
            color="primary"
            onClick={onClick}
          >
            Adicionar
          </Button>

          <Box ml="auto" color="primary.main">
            {isLoading && <CircularProgress size={24} />}
          </Box>
        </>
      )}
    </Toolbar>
  );
};

interface DataTableProps {
  headCells: DataTableHead;
  dataRows: Array<{ [key: string]: number | string | React.ReactNode }>;
  dataTotal?: number;
  isLoading?: boolean;
  errorMessage?: string;
  onCreate?: () => void;
  onView?: (data: any) => void;
  onEdit?: (data: any) => void;
  onDelete?: (data: any) => void;
  onNextPage?: () => void;
  isDisabledNextPage?: boolean;
  customAction?: string;
  customIcon?: IconType;
  onCustom?: (data: any) => void;
}

export default function DataTable({
  headCells,
  dataRows,
  dataTotal = 0,
  isLoading = false,
  errorMessage,
  onCreate,
  onView,
  onEdit,
  onDelete,
  onNextPage,
  isDisabledNextPage = false,
  customAction = 'Ação',
  customIcon = FiTrash,
  onCustom,
}: DataTableProps) {
  const classes = useStylesDataTable();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectData, setSelectData] = React.useState<any | null>(null);

  const isOptions = !!onView || !!onEdit || !!onDelete;

  const count = `Exibindo ${dataRows.length} de ${dataTotal} ${
    dataTotal > 1 ? 'registros' : 'registro'
  }`;

  const CustomIcon = customIcon;

  const handleOpenOptions = (
    event: MouseEvent<HTMLButtonElement>,
    data: any
  ) => {
    setSelectData(data);
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    if (!selectData || !onDelete) return;
    setAnchorEl(null);

    onDelete(selectData);
  };

  const handleCustom = () => {
    if (!selectData || !onCustom) return;
    setAnchorEl(null);

    onCustom(selectData);
  };

  const handleEdit = () => {
    if (!selectData || !onEdit) return;
    setAnchorEl(null);

    onEdit(selectData);
  };

  const handleView = () => {
    if (!selectData || !onView) return;
    setAnchorEl(null);

    onView(selectData);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <EnhancedTableToolbar isLoading={isLoading} onClick={onCreate} />

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            {!!dataRows.length && (
              <EnhancedTableHead
                headCells={headCells}
                classes={classes}
                isOptions={isOptions}
              />
            )}

            <TableBody>
              {dataRows.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={`row-${row.id}`}>
                    {Object.keys(headCells).map((headCell, index) => {
                      return index === 0 ? (
                        <TableCell
                          key={`id-${row[headCell]}-${index}-1`}
                          component="th"
                          scope="row"
                        >
                          {row[headCell]}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={`id-${row[headCell]}-${index}-2`}
                          align="left"
                        >
                          {row[headCell]}
                        </TableCell>
                      );
                    })}

                    {isOptions && (
                      <TableCell>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          color="primary.dark"
                        >
                          <IconButton
                            aria-controls="menu-options-table"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={(event) => handleOpenOptions(event, row)}
                          >
                            <FiMoreVertical color="inherit" />
                          </IconButton>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}

              {!dataRows.length && !isLoading && (
                <TableRow>
                  <TableCell colSpan={Object.keys(headCells).length + 1}>
                    <NoData error={errorMessage} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="space-between" padding={2}>
          {!!dataRows.length && <Box color="primary.main">{count}</Box>}

          {!!dataRows.length && (
            <Button
              aria-label="add-create"
              variant="outlined"
              size="medium"
              color="secondary"
              endIcon={<FiChevronDown />}
              onClick={onNextPage}
              disabled={isDisabledNextPage}
              style={{
                opacity: isDisabledNextPage ? 0.3 : 1,
                borderWidth: isDisabledNextPage ? '0px' : '1px',
              }}
            >
              Ver mais
            </Button>
          )}
        </Box>
      </Paper>

      <Menu
        id="menu-options-table"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        {onView && (
          <MenuItem onClick={handleView}>
            <FiEye fontSize="small" color="inherit" />
            <Box ml={1}>Ver</Box>
          </MenuItem>
        )}

        {onEdit && (
          <MenuItem onClick={handleEdit}>
            <FiEdit fontSize="small" color="inherit" />
            <Box ml={1}>Editar</Box>
          </MenuItem>
        )}

        {onDelete && (
          <MenuItem onClick={handleDelete}>
            <FiTrash fontSize="small" color="inherit" />
            <Box ml={1}>Deletar</Box>
          </MenuItem>
        )}

        {onCustom && (
          <MenuItem onClick={handleCustom}>
            <CustomIcon fontSize="small" color="inherit" />
            <Box ml={1}>{customAction}</Box>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export { DataTable };
