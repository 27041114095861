/* eslint-disable no-var */
import { Avatar, Box, Chip, useMediaQuery } from '@material-ui/core';
import { Card } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
// import { useHistory } from 'react-router-dom';

import UserDataTable from '../../../../shared/components/DataTable/UserDataTable';
import { PageContainer } from '../../../../shared/components/PageContainer';
import UserSearchbar from '../../../../shared/components/SearchBar/userSearchBar';
import { api } from '../../../../shared/services/apiClient';
import { ICustomData, IResponseData } from './types';
import useTable from '../../../../shared/hooks/useTable';

var filtStatus: string[] = [];
var filtProduct: string[] = [];
var filtInterval: string[] = [];

export function Signature() {
  // const history = useHistory();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const [signatures, setSignatures] = useState<ICustomData[]>([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [status, setStatus] = useState('');
  const [interval, setInterval] = useState('');
  const [productName, setProductName] = useState('');
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const queryData = async () => {
    setLoading(true);
    try {
      const data = await api.get(
        `/signature?page=${page}&limit=${limit}&search=${search}&interval=${interval}&product_name=${productName}&status=${status}&order=${order}&orderBy=${orderBy}`
      );

      setTotal(data.data.count);
      const formatUsers: ICustomData[] = [];

      data.data.results.forEach((item: any) => {
        formatUsers.push({
          ...item,
          userName: item.user.name.toUpperCase(),
          customName: (
            <Box display="flex" alignItems="center">
              <Avatar
                src={item.user?.avatar ? item.user?.avatar_url : undefined}
                alt={item.user.name}
              />
              <Box ml={1} display="flex" flexDirection="column">
                <Box>{item.user.name}</Box>
                <Box fontSize="0.75rem">{item.user.email}</Box>
              </Box>
            </Box>
          ),
          signatureType: (
            <Box display="flex" alignItems="center">
              <Chip
                label={item.recurring_interval === 'month' ? 'Mensal' : 'Anual'}
                color="default"
                size="small"
              />
            </Box>
          ),
          customStatus: (
            <Box display="flex" alignItems="center">
              <Chip
                label={
                  item.stripe_subscription_status === 'active'
                    ? 'Ativa'
                    : 'Cancelada'
                }
                color={
                  item.stripe_subscription_status === 'active'
                    ? 'primary'
                    : 'default'
                }
                size="small"
                disabled={item.stripe_subscription_status !== 'active'}
              />
            </Box>
          ),
          updatedAtFormatted: (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <FiCalendar />
              <Box ml={1}>{moment(item.updated_at).format('DD/MM/YYYY')}</Box>
            </Box>
          ),
        });
      });

      setSignatures(formatUsers);
    } catch (error) {
      console.log('erro2', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    queryData();
  }, [page, limit, search, status, interval, productName, order, orderBy]);

  const handleSearch = useCallback((searchValue: string) => {
    setSearch(searchValue);
  }, []);

  const handleFilterStatus = (filterStatus: string, checked: boolean) => {
    if (checked) {
      filtStatus.push(filterStatus);
    } else {
      const index = filtStatus.indexOf(filterStatus);
      if (index > -1) {
        filtStatus.splice(index, 1);
      }
    }

    // eslint-disable-next-line no-unused-expressions
    filtStatus.length === 1 ? setStatus(filtStatus[0]) : setStatus('');
  };
  const handleFilterProduct = (filterProduct: string, checked: boolean) => {
    if (checked) {
      filtProduct.push(filterProduct);
    } else {
      const index = filtProduct.indexOf(filterProduct);
      if (index > -1) {
        filtProduct.splice(index, 1);
      }
    }

    // eslint-disable-next-line no-unused-expressions
    filtProduct.length === 1
      ? setProductName(filtProduct[0])
      : setProductName('');
  };

  const handleFilterInterval = (filterInterval: string, checked: boolean) => {
    if (checked) {
      filtInterval.push(filterInterval);
    } else {
      const index = filtInterval.indexOf(filterInterval);
      if (index > -1) {
        filtInterval.splice(index, 1);
      }
    }

    // eslint-disable-next-line no-unused-expressions, no-implied-eval
    filtInterval.length === 1 ? setInterval(filtInterval[0]) : setInterval('');
  };

  const tableHeadCells = {
    customName: 'Cliente',
    product_name: 'Assinatura',
    signatureType: 'Tipo de Cobrança',
    customStatus: 'Situação',
    updatedAtFormatted: 'Última Atualização',
  };

  const TABLE_HEAD = [
    { id: 'userName', label: 'Cliente' },
    { id: 'product_name', label: 'Assinatura' },
    { id: 'recurring_interval', label: 'Tipo de Cobrança' },
    { id: 'stripe_subscription_status', label: 'Situação' },
    { id: 'updated_at', label: 'Última Atualização' },
  ];

  return (
    <PageContainer>
      <Card>
        <Box>
          <UserSearchbar
            filterName={search}
            searchName={handleSearch}
            searchLabel="Procurar cliente..."
            onFilterStatus={handleFilterStatus}
            onFilterProduct={handleFilterProduct}
            onFilterInterval={handleFilterInterval}
          />
        </Box>
      </Card>

      <Box pb={3} />

      <UserDataTable
        TABLE_HEAD={TABLE_HEAD}
        headCells={tableHeadCells}
        dataRows={signatures}
        dataTotal={total}
        isLoading={loading}
        limit={limit}
        setLimit={(value) => setLimit(value)}
        page={page}
        setPage={(value) => setPage(value)}
        setOrder={(value) => setOrder(value)}
        setOrderBy={(value) => setOrderBy(value)}
      />
    </PageContainer>
  );
}
