import { Box, Divider, useMediaQuery } from '@material-ui/core';
import { ApexOptions } from 'apexcharts';
import { memo, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

import { useTheme } from '../../../../shared/hooks/useTheme';

interface DonutChartProps {
  title: string;
  labels?: string[];
  series: any[];
}
function HomeDonutChart({
  title,
  labels = ['Ativo', 'Inativo'],
  series,
}: DonutChartProps) {
  const { isDark } = useTheme();
  const isNotMobile = useMediaQuery('(min-width:600px)');

  const [options, setOptions] = useState<ApexOptions>(() => {
    return {
      plotOptions: {
        pie: {
          donut: {
            size: '65%',
            labels: {
              show: true,
              value: {
                color: isDark ? '#FFFFFF' : '#666666',
              },
              total: {
                show: true,
                color: isDark ? '#FFFFFF' : '#666666',
              },
            },
          },
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        theme: isDark ? 'dark' : 'light',
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      // yaxis: {
      //   labels: {
      //     formatter: (value): string => {
      //       return `R$ ${formatPrice(value)}`;
      //     },
      //   },
      // },
      fill: {
        opacity: 1,
        type: 'solid',
      },
      legend: {
        position: 'top',
        verticalAlign: 'top',
        fontSize: '14px',
        containerMargin: {
          bottom: 35,
          left: 60,
        },
        labels: {
          colors: isDark ? '#FFFFFF' : '#666666',
        },
      },
      labels,
      colors: ['#FF9988', isDark ? '#646464' : '#b7b7b7'],
    };
  });
  const [seriesChart, setSeriesChart] = useState<any[]>(() => {
    return series;
  });

  useEffect(() => {
    setSeriesChart(series);
  }, [series]);

  return (
    <Box
      borderRadius={8}
      bgcolor="background.paper"
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        p={1}
        my={1}
      >
        <Box fontSize="1rem" color="primary.main">
          {title}
        </Box>
      </Box>

      <Box mb={2}>
        <Divider />
      </Box>

      <Chart
        options={options}
        series={seriesChart}
        type="donut"
        width="100%"
        height={320}
      />
    </Box>
  );
}

export const DonutChart = memo(HomeDonutChart);
