import { QueryKey, useQuery as useReactQuery } from 'react-query';

import { api } from '../../../services/apiClient';

type IReactQueryResponse<T> = {
  result: T;
};

async function getData<IResponseData>(queryUrl: string) {
  const response = await api.get<IResponseData>(queryUrl);
  // if (!response.statusText) {
  //   throw new Error(
  //     'Não foi possível carregar os dados. Erro não identificado no servidor.'
  //   );
  // }

  const data: IReactQueryResponse<IResponseData> = {
    result: response.data,
  };
  return data;
}

function useQuery<T>({ key, url }: { key: QueryKey; url: string }) {
  return useReactQuery<IReactQueryResponse<T>, Error>(key, () =>
    getData<T>(url)
  );
}

export { useQuery };
