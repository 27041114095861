import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { InputPassword } from '../../../../../shared/components/Form/Input/InputPassword';
import { Select } from '../../../../../shared/components/Form/Select';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { IForm } from '../types';
import { schema } from './schema';

export function UserCreate() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      role: {
        id: 1,
        name: 'Admin',
      },
      password: '',
      password_confirmation: '',
    },
  });

  const handlePush = useCallback(() => {
    history.push('/users');
  }, [history]);

  const onSubmit = useCallback(
    ({ name, email, role, password }: IForm) => {
      const formatData = {
        name,
        email,
        role,
        ...(password ? { password } : {}),
      };

      api
        .post('/users', formatData)
        .then(() => {
          msgSuccess('Usuário cadastrado com sucesso!');
          handlePush();
        })
        .catch((err) => {
          msgError(err.response?.data?.message);
        });
    },
    [handlePush, msgSuccess, msgError]
  );

  return (
    <PageContainer component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
        gridGap={24}
        mb={3}
      >
        <Input
          name="name"
          control={control}
          error={errors}
          label="Nome do usuário"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
        />
        <Input
          name="email"
          type="email"
          control={control}
          error={errors}
          label="E-mail do usuário"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
        />
        <Select
          options={[
            { id: 1, name: 'Admin' },
            { id: 2, name: 'Cliente' },
          ]}
          name="role"
          control={control}
          error={errors}
          label="Função do usuário"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
        />
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
        gridGap={24}
        mb={3}
        pt={3}
        borderTop="1px solid #1d1d1d1f"
      >
        <InputPassword
          name="password"
          control={control}
          error={errors}
          label="Senha do usuário"
          placeholder="Defina uma senha entra 6 e 40 caracteres"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
        />
        <InputPassword
          name="password_confirmation"
          control={control}
          error={errors}
          label="Confirmação de senha"
          placeholder="Repita a senha"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          size="large"
        >
          Salvar
        </Button>
      </Box>
    </PageContainer>
  );
}
