/* eslint-disable no-var */
import { Avatar, Box, Chip, useMediaQuery } from '@material-ui/core';
import { Card } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FiShield } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import UserDataTable from '../../../../shared/components/DataTable/UserDataTable';
import { PageContainer } from '../../../../shared/components/PageContainer';
import UserSearchbar from '../../../../shared/components/SearchBar/userSearchBar';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { ICustomData, IResponseData } from './types';
import useTable from '../../../../shared/hooks/useTable';

var filtRole: string[] = [];
var filtStatus: string[] = [];

export function User() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const [users, setUsers] = useState<ICustomData[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const queryData = async () => {
    setLoading(true);
    try {
      const data = await api.get(
        `/users?page=${page}&limit=${limit}&search=${search}&status=${status}&role_id=${role}&order=${order}&orderBy=${orderBy}`
      );

      setTotal(data.data.count);

      const formatUsers: ICustomData[] = [];
      data.data.results.forEach((item: any) => {
        formatUsers.push({
          ...item,
          name: item.name.toUpperCase(),
          email: item.email.toLowerCase(),
          roleType: item.role.name,
          customName: (
            <Box
              display="flex"
              alignItems="center"
              style={{ textTransform: 'capitalize' }}
            >
              <Avatar
                src={item.avatar ? item.avatar_url : undefined}
                alt={item.name}
              />
              <Box ml={1}>{item.name}</Box>
            </Box>
          ),
          roleName: (
            <Box display="flex" alignItems="center">
              <Chip
                label={item.role.name}
                color={item.role.id === 1 ? 'primary' : 'default'}
                size="small"
              />
            </Box>
          ),
          customStatus: (
            <Box display="flex" alignItems="center">
              <Chip
                label={item.status ? 'Ativo' : 'Bloqueado'}
                color={item.status ? 'primary' : 'default'}
                size="small"
                disabled={!item.status}
              />
            </Box>
          ),
        });
      });

      setUsers(formatUsers);
    } catch (error) {
      console.log(error);
      // msgError(error.message);
      // setErrorMessage(error.message)
    }
    setLoading(false);
  };

  useEffect(() => {
    queryData();
  }, [page, limit, search, role, status, order, orderBy]);

  const handleFilterName = (filterName: string) => {
    setSearch(filterName);
    setPage(1);
  };

  const handleFilterStatus = (filterStatus: string, checked: boolean) => {
    if (checked) {
      filtStatus.push(filterStatus);
    } else {
      const index = filtStatus.indexOf(filterStatus);
      if (index > -1) {
        filtStatus.splice(index, 1);
      }
    }

    // eslint-disable-next-line no-unused-expressions
    filtStatus.length === 1 ? setStatus(filtStatus[0]) : setStatus('');
  };

  const handleFilterRole = (filterRole: string, checked: boolean) => {
    if (checked) {
      filtRole.push(filterRole);
    } else {
      const index = filtRole.indexOf(filterRole);
      if (index > -1) {
        filtRole.splice(index, 1);
      }
    }

    // eslint-disable-next-line no-unused-expressions
    filtRole.length === 1 ? setRole(filtRole[0]) : setRole('');
  };

  const handleCreate = useCallback(() => {
    history.push('/users/create');
  }, [history]);

  const handleView = useCallback(
    (user: IResponseData) => {
      history.push(`/users/${user.id}`, {
        user: { ...user, customName: null, roleName: null, customStatus: null },
      });
    },
    [history]
  );

  const handleEdit = useCallback(
    (user: IResponseData) => {
      history.push(`/users/${user.id}/edit`, {
        user: { ...user, customName: null, roleName: null, customStatus: null },
      });
    },
    [history]
  );

  const handleChangeStatus = useCallback(
    (user: IResponseData) => {
      api
        .patch(`/users/${user.id}`, { status: Number(user.status) })
        .then(() => {
          msgSuccess('Status do usuário atualizado com sucesso!');
          window.location.reload();
        })
        .catch((err) => {
          msgError(err.response?.data?.message);
        });
    },
    [msgSuccess, msgError]
  );

  const tableHeadCells = {
    customName: 'Nome',
    email: 'E-mail',
    roleName: 'Função',
    customStatus: 'Situação',
  };

  const TABLE_HEAD = [
    { id: 'name', label: 'Nome' },
    { id: 'email', label: 'E-mail' },
    { id: 'roleType', label: 'Função' },
    { id: 'status', label: 'Situação' },
    { id: '' },
  ];

  return (
    <PageContainer>
      <Card>
        <Box>
          <UserSearchbar
            filterName={search}
            searchName={handleFilterName}
            searchLabel="Procurar cliente..."
            onFilterStatus={handleFilterStatus}
            onFilterRole={handleFilterRole}
            isFilterSelect={!!role || !!status}
          />
        </Box>
      </Card>

      <Box pb={3} />

      <UserDataTable
        TABLE_HEAD={TABLE_HEAD}
        headCells={tableHeadCells}
        dataRows={users}
        dataTotal={total}
        isLoading={loading}
        customAction="Alterar Situação"
        customIcon={FiShield}
        onCustom={(value) => handleChangeStatus(value)}
        onCreate={handleCreate}
        onView={(value) => handleView(value)}
        onEdit={(value) => handleEdit(value)}
        limit={limit}
        setLimit={(value) => setLimit(value)}
        page={page}
        setPage={(value) => setPage(value)}
        setOrder={(value) => setOrder(value)}
        setOrderBy={(value) => setOrderBy(value)}
      />
    </PageContainer>
  );
}
