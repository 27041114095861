import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  DialogActions,
  DialogContent,
  Divider,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { InputCurrency } from '../../../../../shared/components/Form/Input/InputCurrency';
import { InputSlider } from '../../../../../shared/components/Form/Input/InputSlider';
import { Select } from '../../../../../shared/components/Form/Select';
import { Modal } from '../../../../../shared/components/Modal';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useQuery } from '../../../../../shared/hooks/reactQuery/useQuery';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { RecipeProducts } from '../../../components/RecipeProducts';
import { UploadImageRecipe } from '../../../components/UploadImageRecipe';
import { marksSliceQuantity } from './marksSliceQuantity';
import { schema } from './schema';

type IForm = {
  title: string;
  profit_margin: number;
  contribution: number;
  description: string;
  quantity: number;
  unit: string;
  type: {
    id: number;
    name: string;
  };
};

type ISelect = {
  id: number;
  name: string;
};

export type RecipeType = {
  id: number;
  name: string;
  type: 'slices' | 'income';
  slug: string;
};

export type Product = {
  id: number;
  product_name: string;
  price: number;
  quantity: number;
  productTypes: {
    id: number;
    name: string;
  };
  unitMeasurement: {
    id: number;
    name: 'Litro' | 'Mililitro' | 'Grama' | 'Kilograma' | 'Unidade';
  };
  selected?: boolean;
};

export type SelectedProduct = {
  product_id: number;
  quantity: number;
  product_name: string;
  price: number;
  productTypes?: {
    id: number;
    name: string;
  };
  unitMeasurement: {
    id: number;
    name: 'Litro' | 'Mililitro' | 'Grama' | 'Kilograma' | 'Unidade' | string;
  };
  formattedPrice: string;
  newQuantity?: number;
};

export type IRecipeRequest = {
  id?: number;
  title: string;
  description: string;
  image?: string;
  old_image?: string;
  quantity: number;
  unit?: string;
  profit_margin?: number;
  contribution?: number;
  recipe_type_id: number;
  products?: Array<{
    product_id: number;
    quantity: number;
  }>;
};

type IResponseData = { product_types: ISelect[]; unit_measurements: ISelect[] };

export function RecipeCreate() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();
  const [recipeTypes, setRecipeTypes] = useState<RecipeType[]>([]);
  const [selectedRecipeTypeCake, setSelectedRecipeTypeCake] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    []
  );
  const [products, setProducts] = useState<Product[]>([]);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [file, setFile] = useState();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      product_name: '',
      quantity: '',
      unit: 'Porção',
      profit_margin: '',
      contribution: '',
      unitMeasurement: {
        id: 1,
        name: 'Unidade de Medida',
      },
      productType: {
        id: 1,
        name: 'Tipo de Produto',
      },
    },
  });

  const loadProducts = useCallback(() => {
    api.get<{ results: Product[] }>('/admin/products/all').then((response) => {
      const formatProducts = response.data.results.map((product) => ({
        ...product,
        price: product.price,
        selected: !!selectedProducts.find(
          (currentProduct) => currentProduct.product_id === product.id
        ),
      }));

      setProducts(formatProducts);
    });
  }, [selectedProducts]);

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    api.get<RecipeType[]>('/recipes/recipe-types').then((response) => {
      setRecipeTypes(response.data);
    });
  }, []);

  const onSubmit = useCallback(
    ({
      title,
      description,
      contribution,
      profit_margin,
      quantity,
      unit,
      type,
    }: IForm) => {
      setLoading(true);

      const formattedData: IRecipeRequest = {
        title,
        description,
        quantity,
        unit,
        contribution,
        profit_margin,
        recipe_type_id: type.id,
        products: selectedProducts?.map((product) => ({
          product_id: product.product_id,
          quantity: product.newQuantity || product.quantity,
        })),
      };

      if (file) {
        const formDataFile = new FormData();

        formDataFile.append('recipe', file);

        api
          .post('/recipes/image', formDataFile, {
            onUploadProgress: (progressEvent) => {
              const progress: number = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              console.log(`${progress}% carregado... `);
              // setUploadProgress(progress);
            },
          })
          .then((response) => {
            if (response.data?.recipeFileName) {
              // setImageUploaded(response.data.recipeFileName);
              console.log(response.data.recipeFileName);
              // salvar a receita
              // com imagem
              api
                .post('/admin/recipes', {
                  ...formattedData,
                  image: response.data.recipeFileName,
                })
                .then(() => {
                  setFile(undefined);
                  history.push('/recipes');
                })
                .catch((errRecipe) => {
                  setLoading(false);
                  msgError(errRecipe?.response?.data?.message);
                });
            }
          })
          .catch((err) => {
            setLoading(false);
            msgError(err?.response?.data?.message);
          });
      } else {
        // salvar a receita
        // sem imagem
        api
          .post('/admin/recipes', formattedData)
          .then(() => {
            history.push('/recipes');
          })
          .catch((errRecipe) => {
            setLoading(false);
            msgError(errRecipe?.response?.data?.message);
          });
      }
    },
    [file, selectedProducts, msgError, history]
  );

  const handleSelectRecipeType = useCallback(
    (value) => {
      if (!value?.id) return;

      setSelectedRecipeTypeCake(
        recipeTypes.find((recipeType) => recipeType.id === value?.id)?.type ===
          'slices' || false
      );
    },
    [recipeTypes]
  );

  const handleChangeQuantityProduct = useCallback(
    (product: SelectedProduct) => {
      setSelectedProducts((state) =>
        state.map((currentProduct) => {
          if (currentProduct.product_id === product.product_id) {
            return product;
          }
          return currentProduct;
        })
      );
    },
    []
  );

  const handleRemoveProduct = useCallback(
    (id: number) => {
      if (loading || isSubmitting) return;

      setSelectedProducts((state) =>
        state.filter((product) => product.product_id !== id)
      );
      setProducts((state) =>
        state.map((product) =>
          product.id === id ? { ...product, selected: false } : product
        )
      );
    },
    [loading, isSubmitting]
  );

  const handleClickOpenProductModal = () => {
    setOpenProductModal(true);
  };

  const handleCloseProductModal = useCallback(() => {
    loadProducts();
    setOpenProductModal(false);
  }, [loadProducts]);

  const handleSelectProduct = useCallback((product: Product) => {
    setProducts((state) =>
      state.map((currentProduct) => {
        if (currentProduct.id === product.id) {
          return {
            ...currentProduct,
            selected: true,
          };
        }
        return currentProduct;
      })
    );

    setSelectedProducts((state) => [
      ...state,
      {
        ...product,
        product_id: product.id,
        formattedPrice: product.price
          .toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          })
          .replace('.', ','),
      },
    ]);
  }, []);

  return (
    <PageContainer component="form" onSubmit={handleSubmit(onSubmit)}>
      <UploadImageRecipe onFile={(value) => setFile(value)} />

      <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={24} mb={3}>
        <Input
          name="title"
          control={control}
          error={errors}
          label="Nome da ficha técnica"
          variant="outlined"
          fullWidth
          disabled={loading || isSubmitting}
          required
        />
        <Select
          options={recipeTypes}
          name="type"
          control={control}
          error={errors}
          label="Tipo de fixa técnica"
          variant="outlined"
          fullWidth
          disabled={loading || isSubmitting}
          required
          getSelectValue={(value) => handleSelectRecipeType(value)}
        />
      </Box>

      <Box display="flex" flexDirection="column" gridGap={24} mb={3}>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={24} mb={3}>
            <Input
              name="quantity"
              type="number"
              control={control}
              error={errors}
              variant="outlined"
              label="Rendimento/quantidade"
              fullWidth
              required
              disabled={isSubmitting}
            />
            <Input
              name="unit"
              type="text"
              control={control}
              error={errors}
              variant="outlined"
              label="Unidade Rendimento"
              fullWidth
              disabled={isSubmitting}
              placeholder="Fatias"
            />
          </Box>

        <Input
          name="description"
          multiline
          minRows={3}
          control={control}
          error={errors}
          label="Sobre esta ficha técnica"
          variant="outlined"
          fullWidth
          disabled={loading || isSubmitting}
          required
        />

        <Input
          name="profit_margin"
          type="number"
          control={control}
          error={errors}
          variant="outlined"
          label="Margem de lucro (%)"
          fullWidth
          disabled={loading || isSubmitting}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />

        <Input
          name="contribution"
          type="number"
          control={control}
          error={errors}
          variant="outlined"
          label="Margem de Contribuição (%)"
          fullWidth
          disabled={loading || isSubmitting}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Box>

      <Box pb={1} />

      <Box display="flex" flexDirection="column" width="100%">
        <Box
          fontSize="1rem"
          width="100%"
          bgcolor="background.paper"
          padding={1}
          color="secondary.dark"
          textAlign="center"
          borderRadius="4px 4px 0 0"
        >
          Produtos
        </Box>

        <Box display="flex" flexDirection="column" width="100%">
          <RecipeProducts
            products={selectedProducts}
            onChangeInput={(product) => handleChangeQuantityProduct(product)}
            onRemove={(productId) => handleRemoveProduct(productId)}
          />

          <Box display="flex" justifyContent="flex-end" my={1}>
            <Button
              startIcon={<AddOutlined />}
              size="small"
              variant="outlined"
              style={{ paddingLeft: 16, paddingRight: 16 }}
              onClick={() => setIsOpenModal(true)}
            >
              Adicionar Produto
            </Button>
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || isSubmitting}
          size="large"
        >
          Salvar
        </Button>
      </Box>

      <Modal
        title="Adicionar Produtos"
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      >
        <DialogContent dividers style={{ paddingLeft: 8, paddingRight: 8 }}>
          <Table>
            <TableBody>
              {!products.length && (
                <TableRow>
                  <TableCell>
                    <Box
                      display="flex"
                      flexDirection="column"
                      p={2}
                      width="100%"
                      borderRadius={8}
                    >
                      <Box
                        component="strong"
                        fontWeight="normal"
                        fontSize="0.88rem"
                      >
                        Nenhum produto encontrado.
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {!!products.length &&
                products.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>
                      <Box display="flex" justifyContent="flex-start">
                        <Box fontWeight="normal" fontSize="0.88rem">
                          {product.product_name}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          size="small"
                          endIcon={
                            product.selected ? undefined : <AddOutlined />
                          }
                          style={
                            product.selected
                              ? {
                                  paddingRight: 16,
                                  paddingLeft: 16,
                                  border: 'none',
                                  boxShadow: 'none',
                                }
                              : { paddingRight: 16, paddingLeft: 16 }
                          }
                          disabled={product.selected}
                          onClick={() => handleSelectProduct(product)}
                          variant={product.selected ? 'outlined' : 'contained'}
                          color={product.selected ? 'secondary' : 'primary'}
                        >
                          {product.selected ? 'Adicionado' : 'Adicionar'}
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ width: '100%', height: 56 }}
            onClick={() => setIsOpenModal(false)}
            variant="outlined"
            disabled={loading || isSubmitting}
          >
            Fechar
          </Button>
        </DialogActions>
      </Modal>
    </PageContainer>
  );
}
