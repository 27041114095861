import { Box, BoxProps, Typography, Link } from '@material-ui/core';
import React, { useContext } from 'react';

import { ThemeCustomContext } from '../../contexts/ThemeCustomContext';

interface FooterProps extends BoxProps {
  name?: string;
  version?: string;
}

export const Footer: React.FC<FooterProps> = ({ name, version, ...rest }) => {
  const { isDark } = useContext(ThemeCustomContext);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <Box
        color="secondary.main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        width={1}
      >
        <Typography component="div">
          {name && <Box fontSize="body2.fontSize">{name}</Box>}
          {version && (
            <Box fontSize="caption.fontSize" fontWeight="fontWeightMedium">
              Versão {version}
            </Box>
          )}
        </Typography>
      </Box>
    </Box>
  );
};
