type IResponse = {
  results: any[];
  last_page: number;
};

export function assertIsDataResponse(
  response: any
): asserts response is IResponse {
  if (!('results' in response.data && 'last_page' in response.data)) {
    throw new Error('Nenhum registro encontrado.');
  }
}
