import { FC, useCallback } from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { AuthLayout } from '../shared/layouts/AuthLayout';
import { CleanLayout } from '../shared/layouts/CleanLayout';
import { DefaultLayout } from '../shared/layouts/DefaultLayout';

type Location = {
  hash: string;
  pathname: string;
  search: string;
  state: {
    from: {
      hash: string;
      pathname: string;
      search: string;
    };
  };
};

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  pageName?: string;
  isNotFound?: boolean;
  layout?: 'default' | 'clean';
  historyPush?: string;
  breadcrumb?: Array<{
    name: string;
    link: string;
  }>;
}

const Route: FC<RouteProps> = ({
  isPrivate = false,
  pageName,
  isNotFound,
  layout = 'default',
  breadcrumb = [],
  historyPush,
  children,
  ...rest
}) => {
  const isAuthenticated = localStorage.getItem('application.auth.token');
  const getLocation = useLocation<Location>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { from } = getLocation?.state || {
    from: { pathname: '/' },
  };

  const Layout = useCallback(() => {
    if (isPrivate) {
      switch (layout) {
        case 'default':
          return (
            <DefaultLayout
              pageName={pageName}
              breadcrumb={breadcrumb}
              historyPush={historyPush}
            >
              {children}
            </DefaultLayout>
          );
        case 'clean':
          return <CleanLayout pageName={pageName}>{children}</CleanLayout>;
        default:
          return (
            <DefaultLayout
              pageName={pageName}
              breadcrumb={breadcrumb}
              historyPush={historyPush}
            >
              {children}
            </DefaultLayout>
          );
      }
    }

    return <AuthLayout>{children}</AuthLayout>;
  }, [isPrivate, pageName, children, layout, breadcrumb, historyPush]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!isAuthenticated || isNotFound ? (
          <Layout />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/sign-in' : from.pathname,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export { Route };
