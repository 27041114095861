import { Blog } from '../modules/records/pages/Blog';
import { BlogCreate } from '../modules/records/pages/Blog/Create';
import { BlogEdit } from '../modules/records/pages/Blog/Edit';
import { IRoutes } from './types.routes';

const pathname = 'blog';
const pageName = 'Blog';
const pageName2 = 'Blog';

export const blogRoutes: IRoutes[] = [
  {
    path: `/${pathname}`,
    children: <Blog />,
    isPrivate: true,
    exact: true,
    pageName,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/create`,
    children: <BlogCreate />,
    isPrivate: true,
    exact: true,
    pageName: `Novo Post no Blog`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Novo Post no Blog', link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/:id/edit`,
    children: <BlogEdit />,
    isPrivate: true,
    exact: true,
    pageName: `Atualizar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Editar Post no Blog', link: `/${pathname}` },
    ],
  },
];
