import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { Input } from '../../../../shared/components/Form/Input';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { PageAnimation } from '../../components/Animation/PageAnimation';
import { Header } from '../../components/Header';

const schema = yup.object().shape({
  email: yup.string().required('O e-mail é obrigatório.'),
});

type FormValues = {
  email: string;
};

function ForgotPassword() {
  const history = useHistory();

  const { msgSuccess, msgError } = useSnackMessages();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async ({ email }: FormValues) => {
      setLoading(true);

      try {
        await api.post('password/forgot', {
          email,
          app: 1,
        });

        msgSuccess(
          'E-mail enviado! Verifique no seu e-mail as instruções para redefinir sua senha.'
        );

        history.push('/login');
      } catch (err: any) {
        setLoading(false);
        msgError(err?.response?.data?.message);
      }
    },
    [msgSuccess, msgError, history]
  );

  return (
    <PageAnimation>
      <Header
        title="Redefinir senha"
        link="/sign-in"
        linkName="Fazer login"
        mb={4}
      />

      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gridGap={8}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          type="email"
          name="email"
          control={control}
          error={errors}
          variant="outlined"
          label="Insira seu e-mail"
          fullWidth
          required
          disabled={loading}
        />

        <Button
          type="submit"
          loading={loading}
          disabled={loading}
          style={{ width: '100%' }}
        >
          Continuar
        </Button>
      </Box>
    </PageAnimation>
  );
}

export { ForgotPassword };
