import { makeStyles } from '@material-ui/core/styles';

import { drawerWidth } from '../../components/TopNavigation/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(7),
    },
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    maxWidth: `calc(100% - 56px)`,
    [theme.breakpoints.up(600)]: {
      padding: theme.spacing(3),
      maxWidth: `calc(100% - 72px)`,
    },
    [theme.breakpoints.up(700)]: {
      padding: theme.spacing(3),
      maxWidth: `calc(100% - ${drawerWidth}px)`,
    },
  },

  breadcrumb: {
    marginLeft: 'auto',

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  breadcrumbLink: {
    textDecoration: 'none',
    color: 'inherit',
    fontSize: '0.78rem',
  },

  breadcrumbActive: {
    fontSize: '0.78rem',
  },
}));
