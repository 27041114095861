import { Avatar, Box, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import DataTable from '../../../../shared/components/DataTable';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useQueryPaginate } from '../../../../shared/hooks/reactQuery/useQueryPaginate';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { ICustomData, IResponseData } from './types';

export function Blog() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const [posts, setPosts] = useState<ICustomData[]>([]);
  const [total, setTotal] = useState(0);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    status,
    isFetching,
  } = useQueryPaginate<IResponseData>({
    key: 'blog',
    url: `/admin/blog`,
  });

  const handleEdit = useCallback(
    (post: IResponseData) => {
      history.push(`/blog/${post.id}/edit`, {
        blog: {
          title: post.title,
          description: post.description,
          image: post.image,
          image_url: post.image_url,
          id: post.id,
        },
      });
    },
    [history]
  );

  useEffect(() => {
    if (data?.pages) {
      const formatData: ICustomData[] = [];

      data.pages.forEach((group) => {
        setTotal(group.count);

        group.results.forEach((item) => {
          formatData.push({
            ...item,
            formattedImage: (
              <Box
                display="flex"
                alignItems="center"
                maxWidth={120}
                position="relative"
                onClick={() => handleEdit(item)}
                style={{ cursor: 'pointer' }}
              >
                <Avatar
                  src={item?.image_url}
                  style={{ width: '100%', height: '100%', borderRadius: 4 }}
                />
              </Box>
            ),
            formattedDate: (
              <Box display="flex" alignItems="center" width="100%">
                <FiCalendar />
                <Box ml={1}>{moment(item.created_at).format('DD/MM/YYYY')}</Box>
              </Box>
            ),
          });
        });
      });

      setPosts(formatData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pages]);

  const handleCreate = useCallback(() => {
    history.push('/blog/create');
  }, [history]);

  const handleDelete = useCallback(
    (post: IResponseData) => {
      api
        .delete(`/admin/blog/${post.id}`)
        .then(() => {
          msgSuccess('Post deletado com sucesso.');
          refetch();
        })
        .catch((err) => {
          msgError(err?.response?.data?.message);
        });
    },
    [msgError, msgSuccess, refetch]
  );

  const tableHeadCells = {
    formattedImage: '#',
    title: 'Título',
    ...(isDesktop ? { formattedDate: 'Data' } : {}),
  };

  return (
    <PageContainer>
      <DataTable
        headCells={tableHeadCells}
        dataRows={posts}
        dataTotal={total}
        isLoading={status === 'loading' || isFetching}
        errorMessage={error?.message}
        onEdit={(value) => handleEdit(value)}
        onDelete={(value) => handleDelete(value)}
        onCreate={handleCreate}
        onNextPage={fetchNextPage}
        isDisabledNextPage={!hasNextPage || isFetchingNextPage}
      />
    </PageContainer>
  );
}
