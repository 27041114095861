import { Box } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { AnnualPlaningIcon } from '../../../../../shared/components/Icons';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { formatPrice } from '../../../../../shared/utils/formatPrice';
import { AnualPlanningData } from '../types';
import { useStyles } from './styles';

export function AnnualPlanningDetails() {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation<{ annualPlanning: AnualPlanningData }>();
  const total = useMemo(() => {
    const accumulator = state?.annualPlanning.detailsAnualPlannings.reduce(
      (acc, item) => {
        return acc + Number(item.value);
      },
      0
    );

    return formatPrice(accumulator);
  }, [state?.annualPlanning]);

  const annualPlanning = useMemo(() => {
    return {
      ...state?.annualPlanning,
      detailsAnualPlannings: state?.annualPlanning.detailsAnualPlannings.map(
        (item) => ({
          ...item,
          formattedPrice: formatPrice(Number(item.value)),
          formattedStartDate: item?.start_date
            ? moment(item.start_date).format('DD/MM/YY')
            : undefined,
          formattedEndDate: item?.end_date
            ? moment(item.end_date).format('DD/MM/YY')
            : undefined,
        })
      ),
    };
  }, [state?.annualPlanning]);

  useEffect(() => {
    if (!state?.annualPlanning?.id) {
      history.push('/records/annual-planning');
    }
  }, [history, state?.annualPlanning?.id]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end">
        <Button
          size="medium"
          variant="contained"
          color="primary"
          startIcon={<EditOutlined />}
          onClick={() =>
            history.push(`/scheduling-date/${state?.annualPlanning?.id}/edit`, {
              annualPlanning,
            })
          }
        >
          Editar
        </Button>
      </Box>

      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" alignItems="center" mb={1} fontWeight="bold">
          <AnnualPlaningIcon
            style={{
              marginRight: 4,
              width: '42px',
              height: '42px',
            }}
          />
          <Box fontSize="1.4rem" color="secondary.main">
            {state?.annualPlanning.title} -{' '}
            {state?.annualPlanning.formatedEventDate}
          </Box>
        </Box>

        <Box>
          <Box width="100%" pb={2} pt={2} style={{ overflowX: 'auto' }}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Quem?</th>
                  <th>Quando?</th>
                  <th>Como?</th>
                  <th>Quanto?</th>
                  <th>Observações</th>
                </tr>
              </thead>
              <tbody>
                {annualPlanning?.detailsAnualPlannings.map((detail) => (
                  <tr key={detail.id}>
                    <td>{detail.who}</td>
                    <td>
                      <Box display="flex" alignItems="center">
                        <Box>{detail.formattedStartDate}</Box>
                        {detail?.formattedStartDate && (
                          <Box px={1} color="primary.dark">
                            Até
                          </Box>
                        )}
                        <Box>{detail.formattedEndDate || '-'}</Box>
                      </Box>
                    </td>
                    <td>{detail.how}</td>
                    <td>
                      <Box style={{ whiteSpace: 'nowrap' }}>
                        {detail.formattedPrice !== '0,00' && (
                          <>R$ {detail.formattedPrice}</>
                        )}
                      </Box>
                    </td>
                    <td>{detail.additional_observations}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <Box
                      color="primary.dark"
                      fontWeight="bold"
                      textAlign="right"
                    >
                      TOTAL
                    </Box>
                  </td>
                  <td colSpan={2}>
                    <Box textAlign="left">R$ {total}</Box>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
}
