/* eslint-disable jsx-a11y/media-has-caption */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { IForm } from '../types';
import { schema } from './schema';

export function VideoCreate() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      url: '',
      title: '',
      description: '',
    },
  });

  const handlePush = useCallback(() => {
    history.push('/videos');
  }, [history]);

  const onSubmit = useCallback(
    ({ description, title, url }: IForm) => {
      const formatData = {
        description,
        title,
        url,
      };

      api
        .post('/admin/videos', formatData)
        .then(() => {
          msgSuccess('Vídeo cadastrado com sucesso!');
          handlePush();
        })
        .catch((err) => {
          msgError(err.response?.data?.message);
        });
    },
    [handlePush, msgSuccess, msgError]
  );

  return (
    <PageContainer component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" mb={3} width="100%">
        <Input
          help="Exemplo: https://youtube.com/watch..."
          name="url"
          control={control}
          error={errors}
          label="URL do vídeo"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
          style={{ marginBottom: 16 }}
        />

        <Input
          name="title"
          control={control}
          error={errors}
          label="Título do vídeo"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
          style={{ marginBottom: 16 }}
        />

        <Input
          name="description"
          multiline
          minRows={4}
          control={control}
          error={errors}
          label="Descrição"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
        />
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          size="large"
        >
          Salvar
        </Button>
      </Box>
    </PageContainer>
  );
}
