import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useCallback, useEffect, useState } from 'react';

import { Button } from '../../../../shared/components/Button';
import { formatPrice } from '../../../../shared/utils/formatPrice';

export type SelectedProduct = {
  product_id: number;
  quantity: number;
  product_name: string;
  price: number;
  productTypes?: {
    id: number;
    name: string;
  };
  unitMeasurement: {
    id: number;
    name: 'Litro' | 'Mililitro' | 'Grama' | 'Kilograma' | 'Unidade' | string;
  };
  formattedPrice: string;
  newQuantity?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  tbody: {
    overflowX: 'auto',
    '& td': {
      verticalAlign: 'bottom',
    },
  },
  input: {
    width: 70,
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: theme.spacing(1),
    },
  },
  button: {
    minWidth: 40,
    background: `linear-gradient(45deg, ${theme.palette.error.main} 30%, ${theme.palette.error.light} 90%)`,
  },
  noData: {
    background: `linear-gradient(45deg, ${theme.palette.background.paper} 30%, ${theme.palette.background.paper} 90%)`,
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  td: {
    border: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

type IInputChange = {
  newQuantity: number;
  id: number;
  selectProduct: SelectedProduct;
};
interface Props {
  products: SelectedProduct[];
  onChangeInput: (product: SelectedProduct) => void;
  onRemove: (id: number) => void;
}

export function RecipeProducts({ products, onChangeInput, onRemove }: Props) {
  const classes = useStyles();
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    []
  );

  useEffect(() => {
    setSelectedProducts(products);
  }, [products]);

  const handleChangeQuantityProduct = useCallback(
    ({ newQuantity, id, selectProduct }: IInputChange) => {
      if (!newQuantity || newQuantity === 0) return;

      /**
       * corrigir calculo do preço quando a quantidade é em gramas, ou mililitro ou seja
       * qualquer valor diferente de unidade
       */
      const newPrice = formatPrice(
        (selectProduct.price * newQuantity) / selectProduct.quantity
      );

      setSelectedProducts((state) =>
        state.map((product) => {
          if (product.product_id === id) {
            return {
              ...product,
              newQuantity,
              formattedPrice: newPrice,
            };
          }
          return product;
        })
      );

      onChangeInput({
        ...selectProduct,
        newQuantity,
        formattedPrice: newPrice,
      });
    },
    [onChangeInput]
  );

  const handleRemove = (id: number) => {
    onRemove(id);
  };

  return (
    <Table style={{ overflowX: 'auto' }}>
      <TableBody className={classes.tbody}>
        {!selectedProducts.length && (
          <TableRow>
            <TableCell className={classes.td}>
              <Box
                display="flex"
                flexDirection="column"
                py={2}
                width="100%"
                className={classes.noData}
                borderRadius={8}
              >
                <Box component="strong" fontWeight="normal" fontSize="1rem">
                  Nenhum produto adicionado.
                </Box>
                <Box component="span" fontWeight="light" fontSize="0.88rem">
                  Clique no botão Adicionar Produto.
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        )}

        {!!selectedProducts.length &&
          selectedProducts.map((product) => (
            <TableRow key={product.product_id}>
              <TableCell>
                <Box display="flex" flexDirection="column">
                  <Box fontWeight="bold">{product.product_name}</Box>
                  <Box display="flex" alignItems="flex-end">
                    <TextField
                      className={classes.input}
                      defaultValue={product.newQuantity}
                      name="test"
                      variant="outlined"
                      size="small"
                      onChange={(e) =>
                        handleChangeQuantityProduct({
                          newQuantity: Number(e.target.value),
                          id: product.product_id,
                          selectProduct: product,
                        })
                      }
                      required
                    />
                    <Box ml={1} py={1}>
                      <Typography component="div">
                        <Box fontSize="0.88rem">
                          {product?.unitMeasurement?.name}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" py={1}>
                  <Typography component="div">
                    <Box fontSize="0.88rem">
                      R$ {product.newQuantity ? product.formattedPrice : '0,00'}
                    </Box>
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box
                  color="error.main"
                  textAlign="right"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    className={classes.button}
                    size="small"
                    onClick={() => handleRemove(product.product_id)}
                  >
                    <Close />
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
