import { QueryKey, useInfiniteQuery } from 'react-query';

import { api } from '../../../services/apiClient';
import { assertIsDataResponse } from '../assertIsDataResponse';

type IReactQueryResponse<T> = {
  results: T;
  count: number;
  next: number | undefined;
};

type IQuery = {
  url: string;
  pageParam: number;
  params?: string;
};

async function getData<IResponseData>({
  url,
  pageParam = 1,
  params = '',
}: IQuery) {
  const response = await api.get<{
    results: IResponseData[];
    last_page: number;
    count: number;
  }>(`${url}/?page=${pageParam}${params && '&'}${params}`);
  // if (!response.statusText) {
  //   throw new Error('Erro! Nenhum dado encontrado.');
  // }

  assertIsDataResponse(response);

  const data: IReactQueryResponse<IResponseData[]> = {
    results: response.data.results,
    count: response.data.count,
    next:
      response.data.last_page === 0 ||
      response.data.results.length === 0 ||
      response.data.last_page === pageParam
        ? undefined
        : pageParam + 1,
  };
  return data;
}

function useQueryPaginate<T>({
  key,
  url,
  params,
}: {
  key: QueryKey;
  url: string;
  params?: string;
}) {
  return useInfiniteQuery<IReactQueryResponse<T[]>, Error>(
    key,
    (queryKey) =>
      getData<T>({
        url,
        pageParam: queryKey.pageParam,
        params,
      }),
    {
      getNextPageParam: (nextPage) => nextPage.next,
    }
  );
}

export { useQueryPaginate };
