import { yupResolver } from '@hookform/resolvers/yup';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactPlayer from 'react-player';
import ReactQuill from 'react-quill';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { UploadImage } from '../../../components/UploadImage';
import { ICustomData, IForm } from '../types';
import { schema } from './schema';
import 'react-quill/dist/quill.snow.css';

export function BlogEdit() {
  const useStyles = makeStyles((theme: Theme) => ({
    field: {
      borderRadius: '4px',
      height: '200px',
      [theme.breakpoints.down(524)]: {
        height: '230px',
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation<{ blog: ICustomData }>();
  const { msgSuccess, msgError } = useSnackMessages();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(state.blog.description);
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...state?.blog,
    },
  });

  const handlePush = useCallback(() => {
    history.push('/blog');
  }, [history]);

  useEffect(() => {
    if (!state?.blog?.id) {
      handlePush();
    }
  }, [state?.blog?.id, handlePush]);

  const onSubmit = useCallback(
    ({ title }: IForm) => {
      const currentFile = String(file);

      if (
        (file && !state?.blog?.image) ||
        (file &&
          state?.blog?.image &&
          !currentFile.includes(state?.blog?.image))
      ) {
        const formDataFile = new FormData();

        formDataFile.append('blog', file);

        api
          .post('/admin/blog/image', formDataFile, {
            onUploadProgress: (progressEvent) => {
              const progress: number = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              console.log(`${progress}% carregado... `);
              // setUploadProgress(progress);
            },
          })
          .then((response) => {
            if (response.data) {
              // setImageUploaded(response.data.recipeFileName);
              console.log(response.data);
              // salvar a receita
              // com imagem
              api
                .put(`/admin/blog/${state?.blog?.id}`, {
                  description,
                  title,
                  image: response.data?.fileName,
                  old_image: state?.blog?.image,
                })
                .then(() => {
                  setFile(undefined);
                  msgSuccess('Post atualizado com sucesso!');
                  history.push('/blog');
                })
                .catch((error) => {
                  setLoading(false);
                  msgError(error?.response?.data?.message);
                });
              console.log(true);
            }
          })
          .catch((err) => {
            setLoading(false);
            msgError(err?.response?.data?.message);
          });
      } else {
        // salvar a receita
        // sem imagem
        api
          .put(`/admin/blog/${state?.blog?.id}`, {
            description,
            title,
          })
          .then(() => {
            msgSuccess('Post atualizado com sucesso!');
            history.push('/blog');
          })
          .catch((error) => {
            setLoading(false);
            msgError(error?.response?.data?.message);
          });
      }
    },
    [
      msgSuccess,
      msgError,
      file,
      history,
      state?.blog?.id,
      state?.blog?.image,
      description,
    ]
  );

  return (
    <PageContainer component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" mb={3} width="100%">
        <Box>
          A <strong>Imagem</strong> será <strong>redimensionada</strong> com
          tamanho máximo de <strong>800</strong> pixels de largura x{' '}
          <strong>600</strong> pixels de altura, dê preferência a imagens{' '}
          <strong>horizontais</strong>.
        </Box>
        <UploadImage
          preview={state?.blog?.image_url}
          onFile={(value) => setFile(value)}
        />
        <Box mb={2} />
        <Input
          name="title"
          control={control}
          error={errors}
          label="Título"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
        />
        <Box mb={2} />
        {/* <Input
          name="description"
          multiline
          minRows={6}
          control={control}
          error={errors}
          label="Descrição"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
        /> */}
        <fieldset className={classes.field}>
          <legend style={{ marginBottom: '10px' }}>Descrição:</legend>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            style={{
              minHeight: '100px',
              backgroundColor: 'none',
              marginLeft: '10px',
              marginRight: '10px',
              height: '100px',
            }}
          />
        </fieldset>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || loading}
          size="large"
        >
          Salvar
        </Button>
      </Box>
    </PageContainer>
  );
}
