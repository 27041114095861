import { ReactNode, useCallback, useState, createContext } from 'react';

import { useSnackMessages } from '../hooks/useSnackMessages';
import { api } from '../services/apiClient';

interface UploadContextData {
  onUpload: (apiRoute: string, inputName: string, file: any) => void;
  uploadProgress: number;
  urlPreview?: string;
  setUrlPreview?: (image: string) => void;
  setImageUploaded?: (image: string) => void;
  imageUploaded?: string;
  isSubmitting: boolean;
}

type UploadProviderProps = {
  children: ReactNode;
};

export const UploadContext = createContext({} as UploadContextData);

export function UploadProvider({ children }: UploadProviderProps) {
  const { msgError } = useSnackMessages();

  const [isSubmitting, setSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [urlPreview, setUrlPreview] = useState<string>();
  const [imageUploaded, setImageUploaded] = useState<string>();

  const onUpload = useCallback(
    (apiRoute: string, inputName: string, file: any) => {
      if (!file) return;

      if (file.type.split('/')[0] !== 'image') {
        throw new Error('Selecione uma imagem PNG ou JPG.');
      }

      setUrlPreview(URL.createObjectURL(file));

      setSubmitting(true);

      const formDataFile = new FormData();

      formDataFile.append(inputName, file);

      api
        .post(apiRoute, formDataFile, {
          onUploadProgress: (progressEvent) => {
            const progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            console.log(`${progress}% carregado... `);
            setUploadProgress(progress);
          },
        })
        .then((response) => {
          if (response.data?.recipeFileName) {
            setImageUploaded(response.data.recipeFileName);
          }
        })
        .catch((err) => {
          setUploadProgress(0);
          setUrlPreview(undefined);

          msgError(
            err?.response?.data?.message ||
              'Erro! Não foi possível se comunicar com o servidor.'
          );
        })
        .finally(() => {
          setUploadProgress(0);
          setUrlPreview(undefined);
          setSubmitting(false);
        });
    },
    [msgError]
  );

  return (
    <UploadContext.Provider
      value={{
        onUpload,
        uploadProgress,
        urlPreview,
        setUrlPreview,
        isSubmitting,
        imageUploaded,
        setImageUploaded,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
}
