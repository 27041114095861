import { Avatar, Backdrop, Box, Chip, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';

import DataTable from '../../../../shared/components/DataTable';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useQueryPaginate } from '../../../../shared/hooks/reactQuery/useQueryPaginate';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { useStyles } from './styles';
import { ICustomData, IResponseData } from './types';

export function Video() {
  const classes = useStyles();
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const [products, setProducts] = useState<ICustomData[]>([]);
  const [total, setTotal] = useState(0);
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    status,
    isFetching,
  } = useQueryPaginate<IResponseData>({
    key: 'videos',
    url: `/admin/videos`,
  });

  useEffect(() => {
    if (data?.pages) {
      const formatProducts: ICustomData[] = [];

      data.pages.forEach((group) => {
        setTotal(group.count);

        group.results.forEach((item) => {
          formatProducts.push({
            ...item,
            formattedVideo: (
              <Box
                display="flex"
                alignItems="center"
                maxWidth={120}
                position="relative"
              >
                <Box
                  zIndex={1}
                  style={{ cursor: 'pointer' }}
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  onClick={() => setCurrentVideo(item.url)}
                />
                <ReactPlayer url={item.url} height={75} muted playing={false} />
              </Box>
            ),
            formattedDate: (
              <Box display="flex" alignItems="center" width="100%">
                <FiCalendar />
                <Box ml={1}>{moment(item.created_at).format('DD/MM/YYYY')}</Box>
              </Box>
            ),
          });
        });
      });

      setProducts(formatProducts);
    }
  }, [data?.pages]);

  const handleCreate = useCallback(() => {
    history.push('/videos/create');
  }, [history]);

  const handleDelete = useCallback(
    (video: IResponseData) => {
      api
        .delete(`/admin/videos/${video.id}`)
        .then(() => {
          msgSuccess('Vídeo deletado com sucesso.');
          refetch();
        })
        .catch((err) => {
          msgError(err?.response?.data?.message);
        });
    },
    [msgError, msgSuccess, refetch]
  );

  const handleEdit = useCallback(
    (video: IResponseData) => {
      history.push(`/videos/${video.id}/edit`, {
        video: {
          title: video.title,
          description: video.description,
          url: video.url,
          id: video.id,
        },
      });
    },
    [history]
  );

  const handleClose = () => {
    setCurrentVideo(null);
  };

  const tableHeadCells = {
    formattedVideo: '#',
    title: 'Título',
    ...(isDesktop ? { description: 'Descrição', formattedDate: 'Data' } : {}),
  };

  return (
    <PageContainer>
      <DataTable
        headCells={tableHeadCells}
        dataRows={products}
        dataTotal={total}
        isLoading={status === 'loading' || isFetching}
        errorMessage={error?.message}
        onEdit={(value) => handleEdit(value)}
        onDelete={(value) => handleDelete(value)}
        onCreate={handleCreate}
        onNextPage={fetchNextPage}
        isDisabledNextPage={!hasNextPage || isFetchingNextPage}
      />

      <Backdrop
        className={classes.backdrop}
        open={!!currentVideo}
        onClick={handleClose}
      >
        <Box width="100%" height="100%" maxWidth={450} maxHeight={350}>
          {currentVideo && (
            <ReactPlayer
              url={currentVideo}
              height="100%"
              width="100%"
              controls
              playing
            />
          )}
        </Box>
      </Backdrop>
    </PageContainer>
  );
}
