/* eslint-disable jsx-a11y/media-has-caption */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { InputRadio } from '../../../../../shared/components/Form/Input/InputRadio';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { UploadImage } from '../../../components/UploadImage';
import { IForm } from '../types';
import { schema } from './schema';

export function BannerCreate() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      type: '',
      url_redirect: '',
    },
  });

  const onSubmit = useCallback(
    ({ url_redirect, title, type }: IForm) => {
      if (file) {
        const formDataFile = new FormData();

        formDataFile.append('banner', file);

        api
          .post('/admin/ads/image', formDataFile, {
            onUploadProgress: (progressEvent) => {
              const progress: number = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              console.log(`${progress}% carregado... `);
              // setUploadProgress(progress);
            },
          })
          .then((response) => {
            if (response.data) {
              // setImageUploaded(response.data.recipeFileName);
              console.log(response.data);
              // salvar a receita
              // com imagem
              api
                .post('/admin/ads', {
                  title,
                  url_redirect,
                  type,
                  status: true,
                  image: response.data?.fileName,
                })
                .then(() => {
                  setFile(undefined);
                  msgSuccess('Propaganda cadastrada com sucesso!');
                  history.push('/ads');
                })
                .catch((error) => {
                  setLoading(false);
                  msgError(error?.response?.data?.message);
                });
            }
          })
          .catch((err) => {
            setLoading(false);
            msgError(err?.response?.data?.message);
          });
      } else {
        // salvar a receita
        // sem imagem
        api
          .post('/admin/ads', {
            title,
            url_redirect,
            status: true,
            type,
          })
          .then(() => {
            msgSuccess('Propaganda cadastrada com sucesso!');
            history.push('/banners');
          })
          .catch((error) => {
            setLoading(false);
            msgError(error?.response?.data?.message);
          });
      }
    },
    [msgSuccess, msgError, file, history]
  );

  return (
    <PageContainer component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" mb={3} width="100%">
        <UploadImage onFile={(value) => setFile(value)} />

        <Input
          name="title"
          control={control}
          error={errors}
          label="Título"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          required
          style={{ marginBottom: 16 }}
        />

        <Input
          name="url_redirect"
          control={control}
          error={errors}
          label="URL de direcionamento (digitar o endereço completo http://www.EnderecoDoAnunciante.com.br)"
          variant="outlined"
          fullWidth
          disabled={isSubmitting}
          style={{ marginBottom: 16 }}
        />

        <InputRadio
          name="type"
          control={control}
          error={errors}
          options={[
            {
              id: 'fullscreen',
              name: (
                <>
                  Tela cheia - A <strong>Imagem</strong> será{' '}
                  <strong>redimensionada</strong> com tamanho{' '}
                  <strong>400</strong> pixels de largura x <strong>800</strong>{' '}
                  pixels de altura, a imagem deve ser vertical.
                </>
              ),
            },
            {
              id: 'footer',
              name: (
                <>
                  Rodapé - A <strong>Imagem</strong> será{' '}
                  <strong>redimensionada</strong> com tamanho{' '}
                  <strong>800</strong> pixels de largura x <strong>200</strong>{' '}
                  pixels de altura, a imagem deve ser horizontal
                </>
              ),
            },
          ]}
          required
        />
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || loading}
          size="large"
        >
          Salvar
        </Button>
      </Box>
    </PageContainer>
  );
}
