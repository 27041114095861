import { makeStyles } from '@material-ui/core/styles';

export const useUserDetailStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
