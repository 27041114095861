/* eslint-disable react/button-has-type */
import { Box, CircularProgress, Fade, Menu, MenuItem } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import { FormControl, Select, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { MouseEvent, useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { FiEdit, FiEye, FiMoreVertical, FiTrash } from 'react-icons/fi';

import { ICustomData } from '../../../modules/accounts/pages/User/types';
import useTable, { getComparator } from '../../hooks/useTable';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { NoData } from '../NoData';
import { useStylesDataTable, useToolbarStyles } from './styles';
import TableHeadCustom from './TableHeadCustom';

type DataTableHead = { [key: string]: string };

interface HeadCell {
  id: string;
  label: string | number;
  align: 'right' | 'left';
}

interface EnhancedTableProps {
  // eslint-disable-next-line react/no-unused-prop-types
  classes?: ReturnType<typeof useStylesDataTable>;
  headCells: DataTableHead;
  isOptions?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { headCells, isOptions = false } = props;

  const customHeadCells: HeadCell[] = Object.keys(headCells).map(
    (cell, index) => {
      return {
        id: `${headCells[cell]}-${index}`,
        align: 'left',
        label: headCells[cell],
      };
    }
  );

  return (
    <TableHead>
      <TableRow>
        {customHeadCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding="normal">
            {headCell.label}
          </TableCell>
        ))}
        {isOptions && <TableCell padding="normal" />}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  onClick?: () => void;
  isLoading: boolean;
  limit?: number;
  setLimit?: ((data: any) => void) | undefined;
  setPage: (data: any) => void;
}

const EnhancedTableToolbar = ({
  onClick,
  isLoading,
  limit,
  setLimit,
  setPage,
}: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx([classes.root], [!onClick ? classes.minHeight : ''])}
    >
      <>
        <Box
          width="100%"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px 0',
          }}
        >
          {onClick && (
            <Button
              aria-label="add-create"
              variant="outlined"
              size="medium"
              color="primary"
              onClick={onClick}
            >
              Adicionar
            </Button>
          )}
          <Box />
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography style={{ marginRight: '3px' }}>Mostrar </Typography>
            {setLimit && (
              <FormControl
                variant="outlined"
                style={{ width: '80px', margin: 0, padding: 0 }}
                size="small"
              >
                <Select
                  style={{
                    height: '25px',
                    margin: 0,
                    padding: 0,
                  }}
                  size="small"
                  value={limit}
                  onChange={(event) => {
                    setLimit(event.target.value);
                    setPage(1);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            )}
            <Typography style={{ marginLeft: '3px' }}> registros</Typography>
          </Box>
        </Box>

        <Box ml="auto" color="primary.main">
          {isLoading && <CircularProgress size={24} />}
        </Box>
      </>
    </Toolbar>
  );
};

interface DataTableProps {
  TABLE_HEAD: any[];
  headCells: DataTableHead;
  dataRows: Array<{ [key: string]: number | string | React.ReactNode }>;
  dataTotal?: number;
  isLoading?: boolean;
  errorMessage?: string;
  onCreate?: () => void;
  onView?: (data: any) => void;
  onEdit?: (data: any) => void;
  onDelete?: (data: any) => void;
  customAction?: string;
  customIcon?: IconType;
  onCustom?: (data: any) => void;
  limit?: number;
  setLimit?: ((data: any) => void) | undefined;
  page?: number;
  setPage: (data: any) => void;
  setOrder?: (data: any) => void;
  setOrderBy?: (data: any) => void;
}

export default function UserDataTable({
  TABLE_HEAD,
  headCells,
  dataRows,
  dataTotal = 0,
  isLoading = false,
  errorMessage,
  onCreate,
  onView,
  onEdit,
  onDelete,
  customAction = 'Ação',
  customIcon = FiTrash,
  onCustom,
  limit,
  setLimit,
  page,
  setPage,
  setOrder,
  setOrderBy,
}: DataTableProps) {
  const classes = useStylesDataTable();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectData, setSelectData] = React.useState<any | null>(null);
  const [start, setStart] = React.useState<number>(1);

  const { order, orderBy, onSort } = useTable();

  useEffect(() => {
    if (setOrder && setOrderBy) {
      setOrder(order);
      setOrderBy(orderBy);
    }
  }, [order, orderBy]);

  const dataFiltered = applySortFilter({
    tableData: dataRows,
    comparator: getComparator(order, orderBy),
  });

  useEffect(() => {
    if (page && limit) {
      setStart(limit * (page - 1) + 1);
    }
  }, [page, limit]);

  const isOptions = !!onView || !!onEdit || !!onDelete;

  let count;
  if (limit) {
    count = `Exibindo de ${start} até ${Math.min(
      start + limit - 1,
      dataTotal
    )} de ${dataTotal} ${dataTotal > 1 ? 'registros' : 'registro'}`;
  }

  const CustomIcon = customIcon;

  const handleOpenOptions = (
    event: MouseEvent<HTMLButtonElement>,
    data: any
  ) => {
    setSelectData(data);
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    if (!selectData || !onDelete) return;
    setAnchorEl(null);

    onDelete(selectData);
  };

  const handleCustom = () => {
    if (!selectData || !onCustom) return;
    setAnchorEl(null);

    onCustom(selectData);
  };

  const handleEdit = () => {
    if (!selectData || !onEdit) return;
    setAnchorEl(null);

    onEdit(selectData);
  };

  const handleView = () => {
    if (!selectData || !onView) return;
    setAnchorEl(null);

    onView(selectData);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <EnhancedTableToolbar
          isLoading={isLoading}
          onClick={onCreate}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
        />

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            {!!dataRows.length && (
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onSort={onSort}
              />
            )}

            <TableBody>
              {dataFiltered.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={`row-${row.id}`}>
                    {Object.keys(headCells).map((headCell, index) => {
                      return index === 0 ? (
                        <TableCell
                          key={`id-${row[headCell]}-${index}-1`}
                          component="th"
                          scope="row"
                        >
                          {row[headCell]}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={`id-${row[headCell]}-${index}-2`}
                          align="left"
                        >
                          {row[headCell]}
                        </TableCell>
                      );
                    })}

                    {isOptions && (
                      <TableCell>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          color="primary.dark"
                        >
                          <IconButton
                            aria-controls="menu-options-table"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={(event) => handleOpenOptions(event, row)}
                          >
                            <FiMoreVertical color="inherit" />
                          </IconButton>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}

              {!dataFiltered.length && !isLoading && (
                <TableRow>
                  <TableCell colSpan={Object.keys(headCells).length + 1}>
                    <NoData error={errorMessage} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="space-between" padding={2}>
          {!!dataFiltered.length && <Box color="primary.main">{count}</Box>}

          {!!dataFiltered.length && limit && (
            <Pagination limit={limit} total={dataTotal} setPage={setPage} />
          )}
        </Box>
      </Paper>

      <Menu
        id="menu-options-table"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        {onView && (
          <MenuItem onClick={handleView}>
            <FiEye fontSize="small" color="inherit" />
            <Box ml={1}>Ver</Box>
          </MenuItem>
        )}

        {onEdit && (
          <MenuItem onClick={handleEdit}>
            <FiEdit fontSize="small" color="inherit" />
            <Box ml={1}>Editar</Box>
          </MenuItem>
        )}

        {onDelete && (
          <MenuItem onClick={handleDelete}>
            <FiTrash fontSize="small" color="inherit" />
            <Box ml={1}>Deletar</Box>
          </MenuItem>
        )}

        {onCustom && (
          <MenuItem onClick={handleCustom}>
            <CustomIcon fontSize="small" color="inherit" />
            <Box ml={1}>{customAction}</Box>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export { UserDataTable };

type paginationProps = {
  limit: number;
  total: number;
  setPage: (data: any) => void;
};

function Pagination({ limit, total, setPage }: paginationProps) {
  const classes = useStylesDataTable();
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(total / limit);

  const handlePageChange = (newPage: any) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      setCurrentPage(newPage);
    }
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const maxButtons = 5;

    let startPage = currentPage - 2;
    let endPage = currentPage + 2;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(maxButtons, totalPages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxButtons + 1);
    }

    // eslint-disable-next-line no-plusplus
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={i === currentPage ? classes.active : ''}
          style={{ margin: '0.5px' }}
        >
          {i}
        </button>
      );
    }
    return pageButtons;
  };

  return totalPages > 1 ? (
    <div className="pagination">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {'<'}
      </button>
      {renderPageButtons()}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {'>'}
      </button>
    </div>
  ) : (
    <Box />
  );
}

function applySortFilter({
  tableData,
  comparator,
}: {
  tableData: Array<{ [key: string]: number | string | React.ReactNode }>;
  comparator: (a: any, b: any) => number;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  // eslint-disable-next-line no-param-reassign
  tableData = stabilizedThis.map((el) => el[0]);

  return tableData;
}
