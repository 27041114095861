import { AnnualPlanning } from '../modules/records/pages/AnnualPlanning';
import { AnnualPlanningCreate } from '../modules/records/pages/AnnualPlanning/Create';
import { AnnualPlanningDetails } from '../modules/records/pages/AnnualPlanning/Details';
import { AnnualPlanningEdit } from '../modules/records/pages/AnnualPlanning/Edit';
import { IRoutes } from './types.routes';

const pathname = 'scheduling-date';
const pageName = 'Planejamento Anual';
const pageName2 = 'Planejamento Anual';

export const schedulingDateRoutes: IRoutes[] = [
  {
    path: `/${pathname}`,
    children: <AnnualPlanning />,
    isPrivate: true,
    exact: true,
    pageName,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/create`,
    children: <AnnualPlanningCreate />,
    isPrivate: true,
    exact: true,
    pageName: `Cadastrar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Cadastrar', link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/details/:id`,
    children: <AnnualPlanningDetails />,
    isPrivate: true,
    exact: true,
    pageName: `Listagem do ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Listagem', link: `/${pathname}` },
    ],
  },
  {
    path: `/${pathname}/:id/edit`,
    children: <AnnualPlanningEdit />,
    isPrivate: true,
    exact: true,
    pageName: `Atualizar ${pageName2}`,
    push: `/${pathname}`,
    breadcrumb: [
      { name: 'Painel de Controle', link: '/' },
      { name: pageName, link: `/${pathname}` },
      { name: 'Editar', link: `/${pathname}` },
    ],
  },
];
