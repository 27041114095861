import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { ICustomData } from '../types';
import { useUserDetailStyles } from './styles';

export function SignatureDetail() {
  const classes = useUserDetailStyles();
  const history = useHistory();
  const { state } = useLocation<{ signature: ICustomData }>();

  const isDesktop = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    if (!state?.signature?.id) {
      history.push('/signatures');
    }
  }, [history, state?.signature?.id]);

  const handleEdit = () => {
    history.push(`/signatures/${state?.signature.id}/edit`, {
      signature: state?.signature,
    });
  };

  return (
    <PageContainer>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(230px, 1fr))"
        gridGap={24}
        mb={3}
      >
        <Box order={isDesktop ? 0 : 2}>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {state?.signature.user.name}
              </Typography>
              <Typography variant="h5" component="h2">
                {state?.signature.product_name}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                <a href={`mailto:${state?.signature.user.email}`}>
                  {state?.signature.user.email}
                </a>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body2" component="p">
                Para editar os dados da assinatura clique no botão abaixo:
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={handleEdit}>
                Editar
              </Button>
            </CardActions>
          </Card>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          order={1}
        >
          <Avatar
            src={
              state?.signature.user?.avatar
                ? state?.signature.user?.avatar_url
                : undefined
            }
            alt={state?.signature.user?.avatar}
            style={{ width: 220, height: 220, borderRadius: 60 }}
          />
        </Box>
      </Box>
    </PageContainer>
  );
}
